import { Injectable } from '@angular/core'
import { NgRedux } from '@angular-redux/store'

import { ITimeSpentState, IEmailsState } from '../../app.state'
import { TimeSpentActions } from './timespent.actions'
import { DataService, TgetFunction } from '../../services/data.service'

import * as util from '../../services/util.service'
import { traceToggle } from '../../services/util.service';

const trace = util.traceToggle(false)

@Injectable()
export class EmailsActions extends TimeSpentActions {

  protected actionPrefix = 'emails'

  constructor(public ngRedux: NgRedux<IEmailsState>,
              public ds: DataService) {
    super(ngRedux, ds)
  }

  public getTimeSpentVolume(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    trace('EmailsActions - sending: getEmailsVolumeScores')
    this.ds.getEmailsVolumeScores(params, successFunc)
  }

  public getScoresData(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    this.ds.getEmailsData(params, successFunc)
  }

  public getTimeSpentStats(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    this.ds.getEmailsStats(params, successFunc)
  }

  public getEmployeesScores(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    this.ds.getEmployeesEmailsScores(params, successFunc)
  }

}
