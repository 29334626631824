import { Component, OnInit} from '@angular/core'
import { NgRedux, select } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'

import {
  IGlobalState,
  IScoreRow,
  ITimeSpentStats,
  IEmployeeScoreRow
 } from '../../app.state'

import { AppActions } from '../../app.actions'
import { MeetingsActions } from './meetings.actions'
import { TimeSpentComponent } from './timespent.component'

import { MEETINGS_VOLUME } from '../../constants'

@Component({
  selector: 'sa-meetings',
  templateUrl: './timespent.component.html',
  styleUrls: ['./timespent.component.scss']
})
export class MeetingsComponent extends TimeSpentComponent implements OnInit {

  tsActions: MeetingsActions

  @select(['meetings', 'timePickerData']) readonly meetingsTimePickerData$: Observable<any>
  @select(['meetings', 'scores']) readonly meetingsScores$: Observable<IScoreRow[]>
  @select(['meetings', 'timeSpent']) readonly meetingsTimeSpent$: Observable<ITimeSpentStats>
  @select(['meetings', 'employeeScores']) readonly employeeScores$: Observable<IEmployeeScoreRow[]>

  timeSpentAid = MEETINGS_VOLUME

  leftStatHeading = 'Total time spent'
  rightStatHeading = 'Avg. number of particiants'


  constructor(
    public actions: AppActions,
    public timeSpentActions: MeetingsActions,
    public ngRedux: NgRedux<IGlobalState>
  ) {
    super(actions, timeSpentActions, ngRedux)

    this.tabColor = '#5F5EA1'
    this.tsActions = this.timeSpentActions

    this.timePickerData$ = this.meetingsTimePickerData$
    this.timeSpentScores$ = this.meetingsScores$
    this.timeSpent$ = this.meetingsTimeSpent$
  }
}
