import * as _ from 'lodash'

import { BUCKET_OF_10 } from '../constants'
import * as util from '../services/util.service'

//////////////////// bucket functions ///////////////////////////
export function placeInBucket<T>(value: number, min: number, max: number, bucketsArr: T[]): T {
  if (min > max) { throw new Error('min value smaller than max') }
  if (min > value || max < value) { throw new Error(`value: ${value} out of bounds`) }
  if (value === max) { return bucketsArr[bucketsArr.length - 1]}

  const inx = Math.floor(((value - min) / (max - min)) * (bucketsArr.length))
  return bucketsArr[inx]
}

/** take a number in the range of 0 to 100 and place a a bucket of 1 to 10 */
export const percentRangeToBucketOf10 = util.curry(placeInBucket)(BUCKET_OF_10)(100)(0)

/** take a number in the range of 0 to 1 and place a a bucket of 1 to 10 */
export const ratioRangeToBucketOf10   = util.curry(placeInBucket)(BUCKET_OF_10)(1)(0)

/**
 * This class represents a type whose values is an integer between 1 and 10.
 * It has several creator methods creating the type with the appropviate bucket
 * value.
 * It also has several getters for rending:
 *  - The base value (1-10)
 *  - A string value: High, Medium or Low
 *  - A color value: Red, Orange or Green
 */
export class TenBucket {

  private bucket: number

  static createFromRatio = (v: number): TenBucket => {
    return new TenBucket(ratioRangeToBucketOf10(v))
  }

  static createFromPercent = (v: number): TenBucket => {
    return new TenBucket(percentRangeToBucketOf10(v))
  }

  static createFromZScore = (v: number): TenBucket => {
    let val = -1
    if (v <= -3) {
      val = 1
    } else if (v <= -2 && v > -3) {
      val = 2
    } else if (v <= -1 && v > -2) {
      val = 3
    } else if (v <= -0.25 && v > -1) {
      val = 4
    } else if (v <= 0 && v > -0.25) {
      val = 5
    } else if (v <= 0.25 && v > 0) {
      val = 6
    } else if (v <= 1 && v > 0.25) {
      val = 7
    } else if (v <= 2 && v > 1) {
      val = 8
    } else if (v <= 3 && v > 2) {
      val = 9
    } else {
      val = 10
    }
    return new TenBucket(val)
  }

  public constructor(_value: number) {
    this.bucket = _value
  }

  public numericVlaue = (): number => {
    return this.bucket
  }

  public lmhValue = (): string => {
    if (this.bucket === undefined) { throw new Error('value is undefined') }

    let strVal: string
    switch (this.bucket) {
      case 1:
      case 2:
      case 3:
        strVal = 'Low'
        break
      case 4:
      case 5:
      case 6:
      case 7:
        strVal = 'Medium'
        break
      case 8:
      case 9:
      case 10:
        strVal = 'High'
        break
    }
    return strVal
  }

  public colorValue = (): string => {
    const strVal = this.lmhValue()
    let color: string
    switch (strVal) {
      case 'Low':
        color = '#EA4E61'
        break
      case 'Medium':
        color = '#FBB03B'
        break
      case 'High':
        color = '#107B76'
        break
    }
    return color
  }
}
