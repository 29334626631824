import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropDownComponent implements OnInit {
  filteredList: string[]
  listIsOpened: boolean
  @Input() selectedItem: string
  @Input() itemsList: string[]
  @Input() selectedAction: any

  constructor() {
    this.listIsOpened = false
  }

  ngOnInit() {
    this.filteredList = this.itemsList
  }

  itemSelected(item) {
    this.selectedAction(item)
    this.filteredList = this.itemsList
    return false
  }

  arrowClicked() {
    this.listIsOpened = !this.listIsOpened
  }
}
