import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit
 } from '@angular/core'
 import * as _ from 'lodash'

@Component({
  selector: 'sa-number-picker',
  template: `<div class="number-picker-cont">
               <div class="number-picker-val">{{val}}</div>
               <div class="number-picker-controls">
                 <img class="number-picker-controls-up"
                      src='/assets/images/ic_arrow_up_menu.svg'
                      (click)='increaseVal()'>
                 <img class="number-picker-controls-up"
                      src='/assets/images/ic_arrow_down_menu.svg'
                      (click)='decreaseVal()'>
               </div>
             </div>
            `,
  styleUrls: ['./number-picker.component.scss']
})
export class NumberPickerComponent implements OnInit {

  @Input() val: number
  @Input() minVal: number
  @Input() maxVal: number

  @Output() onPickNumber: EventEmitter<number>

  constructor() {
    this.onPickNumber = new EventEmitter()
  }

  ngOnInit() {
    if (_.isNil(this.minVal)) {throw new Error('minVal cannt be null or undefined')}
    if (_.isNil(this.maxVal)) {throw new Error('maxVal cannt be null or undefined')}
    if (this.minVal >= this.maxVal) {throw new Error('minVal has to be smaller than maxVal')}
  }

  increaseVal = () => {
    const v = this.val === this.maxVal ? this.val : this.val + 1
    this.onPickNumber.emit(v)
  }

  decreaseVal = () => {
    const v = this.val === this.minVal ? this.val : this.val - 1
    this.onPickNumber.emit(v)
  }
}
