﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgReduxModule} from '@angular-redux/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { SelectboxComponent } from './selectbox/selectbox.component'
import { SortButtonsComponent } from './sort-buttons/sort-buttons.component'
import { DropDownComponent } from './dropdown/dropdown.component'
import { TogglerComponent } from './toggler/toggler.component'
import { NumberPickerComponent } from './number-picker/number-picker.component'
import { OpenCloseWedgeComponent } from './open-close-wedge.component'

import { InfScrollDirective } from './infscroll.directive'

@NgModule({
  declarations: [
    SelectboxComponent,
    SortButtonsComponent,
    DropDownComponent,
    InfScrollDirective,
    TogglerComponent,
    NumberPickerComponent,
    OpenCloseWedgeComponent
  ],
  imports: [
    NgReduxModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  exports: [
    SelectboxComponent,
    SortButtonsComponent,
    DropDownComponent,
    InfScrollDirective,
    TogglerComponent,
    NumberPickerComponent,
    OpenCloseWedgeComponent
  ]
})

export class WidgetsModule {
  constructor() {}
}
