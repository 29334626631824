import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sa-app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent {

  }
