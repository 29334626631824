import {
  Component,
  OnInit,
  Input
} from '@angular/core'

import * as _ from 'lodash'

import { InteractActions } from '../interact.actions'
import { IInteractCollaborationReportRow } from '../../../app.state'
import * as util from '../../../services/util.service'

export interface IProcessedReportRow {
  name?: string
  group_name: string
  score: number
  group_size?: number
  color?: string
}

@Component({
  selector: 'sa-app-int-coll',
  templateUrl: './interact-coll.component.html',
  styleUrls: ['./interact-coll.component.scss']
})
export class InteractCollComponent implements OnInit {

  @Input() set report (rep: IInteractCollaborationReportRow[]) {
    if (rep.length === 0) {return}
    this.origReport = rep
    this.processedReport = InteractCollComponent.processReport(rep, this.aggregator)
    this.maxScore = _.maxBy(this.processedReport, 'score').score
    this.avgScore = InteractCollComponent.calculateAverageScore(this.processedReport)
  }

  @Input() collaborationScore: number
  @Input() synergyScore: number
  @Input() centralityScore: number

  origReport: IInteractCollaborationReportRow[]
  processedReport: IProcessedReportRow[]
  maxScore: number
  avgScore: number

  aggregator = 'Employees'
  aggregatorsList = ['Employees', 'Departments']

  // =========================== Static functions ============================ //
  static calculateAverageScore = (report: IProcessedReportRow[]): number => {
    const res = report.length > 0 ? _.sumBy(report, 'score') / report.length : 0
    return util.round2(res)
  }

  static processReport = (rep: IInteractCollaborationReportRow[], agg: string): IProcessedReportRow[] => {
    if (agg === 'Employees') { return rep}

    const groupsInx: {[key: string]: IProcessedReportRow} = {}
    _.each(rep, (r) => {
      if (groupsInx[r.group_name] === undefined) {
        groupsInx[r.group_name] = {
          group_name: r.group_name,
          score: r.score,
          group_size: 1,
          color: `#${r.color}`
        }
      } else {
        groupsInx[r.group_name].score += r.score
        groupsInx[r.group_name].group_size += 1
      }
    })
    const groupsArr: IProcessedReportRow[] = _.values(groupsInx)
    return _.sortBy( groupsArr , [(o) => -o.score] )
  }

  constructor(private interactActions: InteractActions) {}

  ngOnInit() {}

  aggregatorSelected = (agg) => {
    this.aggregator = agg
    this.processedReport = InteractCollComponent.processReport(this.origReport, this.aggregator)
    this.maxScore = _.maxBy(this.processedReport, 'score').score
    this.avgScore = InteractCollComponent.calculateAverageScore(this.processedReport)
  }

  getRelativeWidth = (score: number): number => {
    if (_.isNaN(score)) {return 0}
    return 100 * (score / this.maxScore)
  }

  getColorNumber = (color: string) => {
    if (this.aggregator === 'Employees') {return '#FBB03B'}
    return color
  }

  getBorderStyle = (color: string) => {
    if (this.aggregator === 'Employees') {return 'solid 2px #FBB03B'}
    return `solid 2px ${color}`
  }
}
