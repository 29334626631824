﻿import { combineReducers, Reducer, Action } from 'redux';
import { routerReducer } from '@angular-redux/router';
import * as _ from 'lodash'

import { timeSpentReducer,
         emailsReducer,
         meetingsReducer,
         } from './components/timespent/timespent.reducer';
import { dynamicsReducer } from './components/dynamics/dynamics.reducer';
import { interfacesReducer } from './components/interfaces/interfaces.reducer';
import { navReducer } from './components/nav/nav.reducer';
import { alertsReducer } from './components/alerts/alerts.reducer'
import { interactReducer } from './components/interact/interact.reducer'
import { IAppState, INITIAL_STATE_GLOBAL, IGlobalState, CommunicationState } from './app.state'
import { AppActions, IAppAction, } from './app.actions'
import {ROUTER_ROUTE_CHANGED} from './routes'
import { mapReducerCreator } from './components/map/map.reducer'
import {
  EXPLORE_INTERACT,
  EXPLORE_DYNAMICS,
  EXPLORE_INTERFACES
} from './constants'

import * as util from './services/util.service'

const error = util.traceToggle(true)
const trace = util.traceToggle(false)

const appReducer = (lastState: IGlobalState, action: IAppAction ): IGlobalState => {
  if (lastState === undefined) { return INITIAL_STATE_GLOBAL }

  const clonedState = _.cloneDeep(lastState)
  switch (action.type) {

    case AppActions.USER_IS_SIGNED_IN:
    case AppActions.USER_SIGNED_IN: {
      return Object.assign({}, lastState, {
        currentUser: action.userData,
      })
    }

    case AppActions.USER_SIGNED_OUT: {
      return Object.assign({}, lastState, {currentUser: ''})
    }

    case AppActions.TAB_CHANGED: {
      return Object.assign({}, lastState, {currentTab: action.tabName})
    }

    // Although the action here is similar to the on in TAB_CHANGED, the
    // reason they happen is different - one is triggerd by the router, while
    // the other is triggered by clicking the tab. Both need to be hendeled
    case ROUTER_ROUTE_CHANGED: {
      const tabName = (<any>action).payload.slice(1)
      return Object.assign({}, lastState, {currentTab: tabName})
    }

    case AppActions.TIME_INTERVAL_TYPE_CHANGED: {
      return Object.assign({}, lastState, {timeIntervalType: action.intervalType})
    }

    case AppActions.USER_SELECTED_TIME_INTERVAL_SCORES: {
      return Object.assign({}, lastState, {
        scores: action.scores
      })
    }

    case AppActions.USER_SELECTED_TIME_INTERVAL: {
      trace('AppRecucer - changeing curr to: ', action.currentInterval)
      const timeInterval = {
        currentInterval:  action.currentInterval,
        previousInterval: action.previousInterval
      }
      return Object.assign({}, lastState, {
        timeInterval: timeInterval
      })
    }

    case AppActions.AGGREGATOR_TYPE_CHANGED: {
      return Object.assign({}, lastState, {aggregatorType: action.aggregatorType})
    }

    case AppActions.NAV_BAR_TOGGLED: {
      return Object.assign({}, lastState, {navOpened: !lastState.navOpened})
    }

    case AppActions.ALERTS_TOGGLED: {
      return Object.assign({}, lastState, {alertsOpened: !lastState.alertsOpened})
    }

    case AppActions.SPINNER_ON: {
      return Object.assign({}, lastState, {spinner: true})
    }

    case AppActions.SPINNER_OFF: {
      return Object.assign({}, lastState, {spinner: false})
    }

    case AppActions.SNAPSHOTS_CHART_TOGGLE: {
      return Object.assign({}, lastState, {showChart: !lastState.showChart})
    }

    case AppActions.NAV_BAR_HIDE: {
      return Object.assign({}, lastState, { navOpened: false })
    }

    case AppActions.ALERTS_HIDE: {
      return Object.assign({}, lastState, { alertsOpened: false })
    }

    case AppActions.FETCH_SNAPSHOTS: return lastState;
    case AppActions.FETCH_SNAPSHOTS_SUCCESS: {
      return Object.assign({}, lastState, {snapshots: action.snapshots})
    }
    case AppActions.FETCH_SNAPSHOTS_FAIL: return lastState

    case AppActions.FETCH_TIME_PICKER_SNAPSHOTS: return lastState;
    case AppActions.FETCH_TIME_PICKER_SNAPSHOTS_SUCCESS: {
      return Object.assign({}, lastState, {timePickerSnapshots: action.timePickerSnapshots})
    }
    case AppActions.FETCH_TIME_PICKER_SNAPSHOTS_FAIL: return lastState

    case AppActions.SERVER_DISCONNECTED: {
      return Object.assign({}, lastState, {communiationState: CommunicationState.disconnected})
    }
    case AppActions.SERVER_CONNECTED: {
      return Object.assign({}, lastState, {communiationState: CommunicationState.connected})
    }
    case AppActions.FETCH_CONFIG_PARAMS: return lastState
    case AppActions.FETCH_CONFIG_PARAMS_FAIL: {
      error('AppReducer, FETCH_CONFIG_PARAMS_FAIL')
      return lastState
    }
    case AppActions.FETCH_CONFIG_PARAMS_SUCCESS: {
      return Object.assign({}, lastState, {configParams: action.configParams})
    }

    case AppActions.DOWNLOAD_REPORT: {
      return Object.assign({}, lastState, {waitingForReport: true})
    }

    case AppActions.DOWNLOAD_REPORT_SUCCESS: {
      return Object.assign({}, lastState, {waitingForReport: false})
    }
  }
  return lastState;
}

export const rootReducer: Reducer<IAppState> = combineReducers<IAppState>({
  meetings: meetingsReducer,
  emails: emailsReducer,
  dynamics: dynamicsReducer,
  interfaces: interfacesReducer,
  global: appReducer,
  router: routerReducer,
  groups: navReducer,
  alerts: alertsReducer,
  interact: interactReducer,
  interactMap: mapReducerCreator(EXPLORE_INTERACT),
  dynamicsMap: mapReducerCreator(EXPLORE_DYNAMICS)
})
