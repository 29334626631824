import { Pipe, PipeTransform } from '@angular/core';
import * as util from '../services/util.service'

@Pipe({name: 'toPercent'})
export class PercentFormatter implements PipeTransform {
  transform(value: number): string {
    if (value === null) { return '0.0%' }
    if (value <= 1 && value >= 0) {
      return `${util.round1(100 * value)}%`
    }
    if (value < 0) {
      return 'NA'
    }
    return `${util.round1(value)}%`
  }
}
