﻿import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl, FormArray } from '@angular/forms';

import { UserModel } from '../../user/user.model'
import * as Consts from '../../../constants'

import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { IGlobalState } from '../../../app.state'

import { AjaxService } from '../../../services/ajax.service'

/**
Created: 7.8.17 - Michael K.
This component controls system settings for all users, for viewing and editing.
This screen is available for users with admin permissions only.
The settings in this section apply to all users of a given company

Form fields:

 - Session timeout - how much time can a user be idle before the system logs him out
 - Password section
   * Password update - period of time a user must update his password for the system
   * Login attempts - how many times a user can attempt to log in unsuccessfully, before
     he is locked out
   * Required characters - enforce which characters a password must contain to be legal
Sending data:

 - The 'Save changes' button is the submit button for the form
 - It is disabled by default
 - It is enabled when either is true:
   * Values were modified (using the FormGroup.dirty)
 - OnSubmit() action will send the data to the server only if the form is
   valid (using FormGroup.valid)
 */

@Component({
  selector: 'sa-settings-security',
  templateUrl: './settings-security.component.html',
  styleUrls: ['./settings-security.component.scss', '../settings.component.scss']
})
export class SettingsSecurityComponent implements OnInit {

  /********** MENU DEFINITIONS **********/

  readonly sessionTimeouts = [
    { text: '10 minutes' }, { text: '60 minutes' },
    { text: '120 minutes' }, { text: 'Never' }
  ];

  readonly passwordUpdateTimes = [
    { text: '1 month' }, { text: '3 month' }, { text: '6 month' }
  ];

  readonly maxLoginAttempts = [
    { text: '3' }, { text: '4' },
    { text: '5' }, { text: 'Never' }
  ];

  readonly minPasswordLength = [
    { text: '6' }, { text: '8' },
    { text: '10' }, { text: 'Any length' }
  ];

  /****************************************/

  @select(['global']) readonly currentUserState$: Observable<IGlobalState>
  userData: UserModel

  securityForm: FormGroup

  togglePasswordSettings      = false
  toggleSessionTimoutSettings = false

  constructor(private ajaxSrv: AjaxService) {
    this.currentUserState$.subscribe(globalState => {
      if (globalState.currentUser === undefined) { return }
      this.userData = <UserModel>globalState.currentUser;
      this.initForm();
    })
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const fb = new FormBuilder()

    this.securityForm = fb.group({
      'session_timeout': [''],
      'password_update_time': [''],
      'login_attempts': [''],
      'required_characters': fb.array(this.initRequiredCharValues(this.userData.required_chars_in_password, fb))
    });

    this.securityForm.controls['session_timeout'].setValue(String(this.userData.session_timeout));
    this.securityForm.controls['password_update_time'].setValue(String(this.userData.password_update_interval));
    this.securityForm.controls['login_attempts'].setValue(String(this.userData.max_login_attempts));
  }

  onSubmit(formData: any) {

    if (!this.securityForm.valid) {
      console.log('WARNING: You are trying to submit an invalid form');
      return;
    }

    // FIXME - currently , the multiple choise is represented with a string -  e.g. '0110'
    // Find a better representation. Note, that on the server end - rails has a problem
    // With nested arrays in the params inside the controller
    const reqCharsString = this.convertRequiredCharsToString(formData.required_characters);

    const data = {session_timeout : formData.session_timeout, password_update_time: formData.password_update_time,
      login_attempts: formData.login_attempts, required_characters: reqCharsString
    }

    // FIXME - should move to a separate service/component
    this.ajaxSrv.post(
      'v3/setting/update_security_settings',
      data,
      () => this.onUpdateSuccess(Consts.SETTINGS_SECURITY),
      () => this.onUpdateError(Consts.SETTINGS_SECURITY))
  }

  convertRequiredCharsToString(requiredChars: any): string {
    let res = '';
    for (const c of requiredChars) {
      res += c.isChosen ? '1' : '0'
    }
    return res;
  }

  onUpdateSuccess(settingType: string) {
    if (settingType !== Consts.SETTINGS_SECURITY) { return; }

    // Reset form to inital state - as if the user hasn't touched it yet
    this.securityForm.markAsPristine()
    location.reload();
  }

  onUpdateError(settingType: string) {
    if (settingType !== Consts.SETTINGS_SECURITY) { return; }

    // Reset form to inital state - as if the user hasn't touched it yet
    this.securityForm.markAsPristine()
  }

  initRequiredCharValues(arr: any, fb: FormBuilder) {
    const newArr = []
    for (const c of arr) {
      newArr.push(fb.group({
        text: c.text,
        isChosen: c.enabled
      }));
    }
    return newArr
  }
}
