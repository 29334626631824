﻿import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Subscription, } from 'rxjs/Subscription';

import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { IUser, IGlobalState } from '../../../app.state'

import { UserModel, UserType } from '../../user/user.model'
import * as Consts from '../../../constants'

import { AjaxService } from '../../../services/ajax.service'

/*
Created: 4.8.17 - Michael K.
This component controls a user's personal info, for viewing and editing.

Form fields:

 - First name - Validations: required, letters only with whitespace, dash
 - Last name - Validations: required, letters only with whitespace, dash
 - Email - this field is not editable. It displays the user's email,
   which is also his username in the system.
 - Encryption toggle - a user can set an option to encrypt (password protect)
   reports that are compiled by the system. Only users with admin privileges
   can encrypt reports. This section won't display for a user who is not
   an admin.
   * Activating encryption (with toggle) allows the user to enter a password
   * This password will be saved to the database. Next time the user will
     request reports, they will be password protected with the above password.
   * Deactivating encryption, and saving, will update the password to an empty
     string in the database. For the system, this means the user does not
     want to encrypt reports.
   * Activating encryption and leaving the encryption password blank will
   * behave the same as deactivating the encryption
 - Encryption password - can be blank. Editable only if encryption activated
   via the toggle.

Sending data:

 - The 'Save changes' button is the submit button for the form
 - It is disabled by default
 - It is enabled when either is true:
   * Form text fields were modified (using the FormGroup.touched)
   * The allow submit override flag is true on the component
 - OnSubmit() action will send the data to the server only if the form is
   valid (using FormGroup.valid)
 */
@Component({
  selector: 'sa-settings-personal-info',
  templateUrl: './settings-personal-info.component.html',
  styleUrls: ['./settings-personal-info.component.scss', '../settings.component.scss']
})
export class SettingsPersonalInfoComponent implements OnInit {

  @select(['global', 'currentUser']) readonly currentUserState$: Observable<UserModel>;
  userData: UserModel;

  personalInfoForm: FormGroup;
  editPasswordForm: FormGroup;

  /** Setting this to true will enable the submit button regardles of the form state.
  This DOES NOT bypass validations */
  canSubmitFormOverride = false;

  // Personal info form field controls
  firstNameControl: AbstractControl;
  lastNameControl: AbstractControl;
  emailControl: AbstractControl;
  encryptionKeyControl: AbstractControl;

  // Edit password form field controls
  oldPasswordControl: AbstractControl;
  newPasswordControl: AbstractControl;
  repeatNewPasswordControl: AbstractControl;

  showEditPassword = false;

  encryptionChecked: boolean;

  triedToSubmitInvalidForm = false;

  constructor(private ajaxSrv: AjaxService) {
    this.userData = new UserModel({email: ''});
    this.currentUserState$.subscribe(currentUser => {
      if (currentUser === undefined || currentUser.email === undefined || Object.keys(currentUser).length === 0) { return }
      this.userData = currentUser;
      this.initForm();
    })
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    // if (this.userData === undefined || this.userData.email === undefined) {return}
    this.personalInfoForm = (new FormBuilder()).group({
      'first_name': ['', [Validators.required, Validators.pattern(Consts.REGEX_NAMES)]],
      'last_name': ['', [Validators.required, Validators.pattern(Consts.REGEX_NAMES)]],
      'email': [''],
      'encryption_key': ['', [Validators.required, Validators.minLength(7)]]
    });

    this.firstNameControl = this.personalInfoForm.controls['first_name'];
    this.lastNameControl = this.personalInfoForm.controls['last_name'];
    this.emailControl = this.personalInfoForm.controls['email'];
    this.encryptionKeyControl = this.personalInfoForm.controls['encryption_key'];

    // Set initial form values
    this.firstNameControl.setValue(String(this.userData.first_name));
    this.lastNameControl.setValue(String(this.userData.last_name));
    this.emailControl.setValue(String(this.userData.email));
    this.emailControl.disable();
    this.encryptionKeyControl.setValue(this.userData.isEncryptReport() ? String(this.userData.reports_encryption_key) : '');
    this.encryptionChecked = this.userData.isEncryptReport();

    this.editPasswordForm = (new FormBuilder()).group({
      'old_password': ['', [Validators.required, Validators.minLength(8)]],
      'new_password': ['', [Validators.required, Validators.minLength(8)]],
      'repeat_new_password': ['', [Validators.required, Validators.minLength(8)]]
    });

    this.oldPasswordControl = this.editPasswordForm.controls['old_password'];
    this.newPasswordControl = this.editPasswordForm.controls['new_password'];
    this.repeatNewPasswordControl = this.editPasswordForm.controls['repeat_new_password'];
  }

  /** Add here conditions to bypass form.valid property upon submit */
  bypassValidations(): boolean {
    // Don't check validations of encryption key if user unchecked, and doesn't want to set a key
    if (!this.encryptionKeyControl.valid && !this.encryptionChecked) {
      return true;
    }
    return false;
  }

  /****************************** EDIT PERSONAL INFO FORM *************************/

  allowSubmitOverride() {
    this.canSubmitFormOverride = true;
  }

  onSubmitPersonalInfo(value: any) {
    if (!this.personalInfoForm.valid && !this.bypassValidations()) {
      console.log('WARNING: You are trying to submit an invalid form');
      this.triedToSubmitInvalidForm = true;
      return;
    }
    this.triedToSubmitInvalidForm = false;

    const formData = {
      'first_name': value.first_name,
      'last_name': value.last_name,
      'reports_encryption_key': this.encryptionChecked ? String(value.encryption_key) : '' };

    this.ajaxSrv.post(
      'v3/setting/update_user_info',
      formData,
      this.onUpdateSuccessPersonalInfo(),
      () => {
        console.log('3');
        this.initForm(); }
    );
  }

  onUpdateSuccessPersonalInfo() {
    return (d) => {
      console.log('onUpdateSuccessPersonalInfo() - got: ', d)
      if (!this.encryptionChecked) {
        this.encryptionKeyControl.setValue('');
      }

      // Reset form to inital state - as if the user hasn't touched it yet
      this.personalInfoForm.markAsPristine();
      location.reload();
    }
  }

  /********************************************************************************/

  /****************************** EDIT PASSWORD FORM ******************************/

  /**
   * Check if user entered old password as new password
   */
  get newPasswordEqualsOld(): boolean {
    return this.editPasswordForm.controls['old_password'].value === this.editPasswordForm.controls['new_password'].value;
  }

  /**
   * Check if new password is the same when repeated
   */
  get newPasswordsMatch(): boolean {
    return this.editPasswordForm.controls['new_password'].value === this.editPasswordForm.controls['repeat_new_password'].value;
  }

  onSubmitEditPassword(value: any) {
    if (!this.editPasswordForm.valid || !this.newPasswordsMatch) {
      console.log('WARNING: You are trying to submit an invalid form')
      return;
    }

    const formData = { 'old_password': value.old_password, 'new_password': value.new_password };

    this.ajaxSrv.post(
      'v3/setting/edit_password',
      formData,
      this.onUpdateSuccessEditPassword(),
      () => { this.editPasswordForm.reset() }
    )
  }

  onUpdateSuccessEditPassword() {
    return () => {
      this.showEditPassword = false
      this.editPasswordForm.reset()
    }
  }

  /********************************************************************************/
}
