import { Component, Input, OnInit} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash'

import * as util from '../../services/util.service'

import { IEmployeeScoreRow, ICollaborationSegments } from '../../app.state';

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-employee-scores',
  templateUrl: './employee-scores.component.html',
  styleUrls: ['./employee-scores.component.scss']
})
export class EmployeeScoresComponent implements OnInit {

  @Input() tabColor: string

  @Input() data$: Observable<IEmployeeScoreRow[]>

  @Input() selectedSegments$: Observable<any>
  aggregator: string
  segments: ICollaborationSegments
  @Input() selectedAlgorithmName: string

  @Input() employeeClicked: (e: any) => void

  rawData: IEmployeeScoreRow[]= []
  filteredData: IEmployeeScoreRow[]= []

  /** Displayed data */
  sortedData: IEmployeeScoreRow[]= []

  /**
   * Method to filter employee scores, relying on aggregator + algorithm selection
   * @param data - data to filter
   * @param filterBy - filter condition - either by groups (departments) or by offices
   * @param segments - the specific segments selected by the user, to filter by - row is group/office name
   * and column is aid
   */
  static filterEmployeeScores = (data: IEmployeeScoreRow[], filterBy: string, segments: ICollaborationSegments): IEmployeeScoreRow[] => {
    // No aggregator (department/office) and no algorithm
    if (segments.column === '' && segments.row === '') { return data }

    const row = segments.row

    if (segments.column === '' && row !== '') {
      // No algorithm
      return _.filter(data, (d: IEmployeeScoreRow) => {
        return (filterBy === 'Department' && d.group_name === row)
                || (filterBy !== 'Department' && d.office_name === row)
      })
    } else if (segments.row === '' && !(segments.column === '')) {
      // No aggregator selected
      return _.filter(data, (d: IEmployeeScoreRow) => {
        return d.aid.toString() === segments.column;
      })
    }
    return _.filter(data, (d: IEmployeeScoreRow) => {
      return (d.aid.toString() === segments.column
        && ((filterBy === 'Department' && d.group_name === row)
          || (filterBy !== 'Department' && d.office_name === row)))
    })
  }

  static orderDataByScore = (data: IEmployeeScoreRow[]): IEmployeeScoreRow[] => {
    return _.orderBy(data, (d: IEmployeeScoreRow) => d.score, ['desc']);
  }

  constructor() {}

  ngOnInit() {
    this.selectedSegments$.subscribe(params => {
      if (params === undefined || params === null || this.rawData === undefined) {return}
      this.aggregator = params.aggregator;
      this.segments = params.segments;
    })

    this.data$.subscribe(data => {
      trace('EmployeeScoresComponent - data$ was changed')
      if (data === undefined || data === null) {return}
      this.rawData = data;
      const agg = this.aggregator === undefined ? 'Department' : this.aggregator
      this.sortedData = EmployeeScoresComponent.orderDataByScore(this.rawData);
    })
  }
}

