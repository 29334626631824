
/** SETTINGS */
export const SETTINGS_ADMIN_MESSAGE = 'Your account type is admin';
export const SETTINGS_NON_ADMIN_MESSAGE = 'Your account is limited';
export const SETTINGS_ADMIN_MESSAGE_TOOLTIP = 'An admin may view system reports as well as add other users to the system';
export const SETTINGS_NON_ADMIN_MESSAGE_TOOLTIP = `You may view your team's reports`;

/** TIME PICKER */
export const TIME_PICKER_COLLAPSE_BUTTON_TEXT_MONTHLY = 'AVERAGE MONTHLY';
export const TIME_PICKER_COLLAPSE_BUTTON_TEXT_QARTERLY = 'AVERAGE QUARTERLY';
export const TIME_PICKER_COLLAPSE_BUTTON_TEXT_AVG = 'AVERAGE'
export const TIME_PICKER_COLLAPSE_BUTTON_TEXT_SIX_MONTHS = 'IN 6 MONTHS';
export const TIME_PICKER_COLLAPSE_BUTTON_TEXT_YEARLY = 'AVERAGE YEARLY TIME SPENT';

export const TIME_PICKER_DEFAULT_TOOL_TIP = 'Average time spent in the period per employee'

/** Interact */
export const INTERACT_COLLABORTION_SUBTITLE =
        'This screen displays number of connections \
        related to each participant'
export const INTERACT_EXPLORE_SUBTITLE =
        'The map can be easily navigate by using the controls \
         below, or by zeroing on a specific department from the nav \
         bar on the left.'

/** Validation errors */
export const VALIDATION_MSG_GENERAL = 'Error in field'
export const VALIDATION_MSG_FIELD_REQUIRED = 'This is a required field'
export const VALIDATION_MSG_FIELD_LENGTH_LONG = 'Max length exceeded'
export const VALIDATION_MSG_FIELD_LENGTH_SHORT = 'Text is too short'
