import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Action } from 'redux';
import * as _ from 'lodash'

import { IAlertsState, IAlert } from '../../app.state'
import { DataService } from '../../services/data.service'
import * as util from '../../services/util.service'

const trace = util.traceToggle(false)


export interface IAlertAction extends Action {
  alid?: number
  alerts?: IAlert[],
}

@Injectable()
export class AlertActions {

  static readonly FETCH_ALERTS         = 'alerts fetch'
  static readonly FETCH_ALERTS_SUCCESS = 'alerts fetch success'
  static readonly FETCH_ALERTS_ERROR   = 'alerts fetch error'

  static readonly ALERT_ACKNOWLEDGE = 'alerts acknowledge'

  constructor(
    private ngRedux: NgRedux<IAlertsState>,
    private dm: DataService) {}

    acknowledgeAlert(id: number) {
      trace('AlertActions - In acknowledgeAlert() of alert id: ', id)
      this.ngRedux.dispatch({type: AlertActions.ALERT_ACKNOWLEDGE, alid: id})
      this.dm.acknowledgeAlert({alid: id})
    }

    fetchAlerts(params) {
      trace('AlertActions - In fetchAlerts()')
      this.ngRedux.dispatch({type: AlertActions.FETCH_ALERTS})
      this.dm.getAlertsData(params, (res) => {
        trace('AlertActions - In fetchAlerts() - fetch success: res = ', res)
        this.fetchAlertsSuccess(res)
      })
    }

    fetchAlertsSuccess(res) {
      setTimeout(() => {
        this.ngRedux.dispatch({type: AlertActions.FETCH_ALERTS_SUCCESS, alerts: res})
      }, 200)
    }

    fetchGroupsError() {
      this.ngRedux.dispatch({type: AlertActions.FETCH_ALERTS_ERROR})
    }
}
