import * as _ from 'lodash'

import {
  ILink,
  INode,
  IDept,
  ICsMap
} from './combine.service'

import {
  IMapData,
  IMapGroup,
  IMapState
} from '../../app.state'


const NODE_TYPE_SINGLE = 'single'
const NODE_TYPE_COMBO  = 'combo'

/**
 * The adapter bridges the gap between Keylines data structures and the CombineService's
 * structures.
 */
export function klToCsLink(l: IMapData): ILink {
  return {
    from_id: parseInt(l.id1, 0) ,
    from_type: NODE_TYPE_SINGLE,
    to_id: parseInt(l.id2, 0),
    to_type: NODE_TYPE_SINGLE,
    weight: l.w,
    hi: l.hi,
    c: l.c
  }
}

export function klToCsLinks (ll: IMapData[]): ILink[] {
  return _.map(ll, (l) => klToCsLink(l))
}

export function csToKlLink (l: ILink): IMapData {
  return {
    type: 'link',
    id: `L-${l.from_id}-${l.to_id}`,
    id1: l.from_id.toString(),
    id2: l.to_id.toString(),
    w: l.weight,
    a1: l.isBiDirectional,
    a2: true,
    hi: l.hi,
    c: l.c
  }
}

export function csToKlLinks (ll: ILink[]): IMapData[] {
  return _.map(ll, (l) => csToKlLink(l))
}

/**
 * Nodes
 */
export function klToCsNode (n: IMapData): INode {
  return {
    id: parseInt(n.id, 0),
    gid: n.group_id,
    orig_gid: n.orig_group_id,
    group_name: n.group_name,
    type: NODE_TYPE_SINGLE,
    image_url: n.u,
    rate: n.d,
    color: n.c,
    name: n.t,
    role: n.role,
    rank: n.rank,
    office: n.office_name,
    gender: n.gender,
    job_title: n.job_title,
    hi: n.hi,
    fi: n.fi,
    color_id: n.color_id,
    e: n.e,
    ha0: n.ha0
  }
}

export function klToCsNodes (nl: IMapData[]): INode[] {
  return _.map(nl, (n) => klToCsNode(n))
}

export function csToKlNode(n: INode): IMapData {
  return {
    type: 'node',
    id: (typeof n.id === 'string' ? n.id : n.id.toString()),
    t: n.name,
    c: n.color,
    color_id: n.color_id,
    d: n.rate,
    u: n.image_url,
    group_id: n.gid,
    orig_group_id: n.orig_gid,
    group_name: n.group_name,
    rank: n.rank,
    role: n.role,
    office_name: n.office,
    job_title: n.job_title,
    gender: n.gender,
    hi: n.hi,
    fi: n.fi,
    e: n.e,
    ha0: n.ha0
  }
}

export function csToKlNodes(nl: INode[]): IMapData[] {
  return _.map(nl, (n) => csToKlNode(n))
}

/**
 * IGroups
 */
export function toCsGroup(g: IMapGroup): IDept {
  return {
    id: g.gid,
    parentId: g.parentId,
    name: g.name,
    color_id: g.color_id
  }
}

export function toCsGroups(gl: IMapGroup[]): IDept[] {
  return _.map(gl, (g) => toCsGroup(g) )
}

export function fromCsGroup(g: IDept): IMapGroup {
  return {
    gid: g.id,
    parentId: g.parentId,
    name: g.name,
    color_id: g.color_id
  }
}

export function fromCsGroups(gl: IDept[]): IMapGroup[] {
  return  _.map(gl, (g) => fromCsGroup(g))
}

/**
 * All together now
 */
export function klToCsAll(map: IMapState): ICsMap {
  return {
    nodes: klToCsNodes(map.nodes),
    links: klToCsLinks(map.links),
    groups: toCsGroups(map.groups),
    orig_groups: map.origGroups,
    type: map.type,
    redraw: map.redraw,
    filters: map.filters,
    hideNames: map.hideNames,
    uniColors: map.uniColors,
    questionnaireName: map.questionnaireName,
    department: map.department,
    questionTitle: map.questionTitle,
    colorBy: map.colorBy,
    filtersStr: map.filtersStr,
    clickToIsolateNodeId: map.clickToIsolateNodeId,
    edgesFromFilter: map.edgesFromFilter,
    edgesToFilter: map.edgesToFilter,
    showCoreNetwork: map.showCoreNetwork
  }
}

export function csToKlAll(map: ICsMap): IMapState {
  return {
    nodes: csToKlNodes(map.nodes),
    links: csToKlLinks(map.links),
    groups: fromCsGroups(map.groups),
    origGroups: map.orig_groups,
    type: map.type,
    redraw: map.redraw,
    filters: map.filters,
    hideNames: map.hideNames,
    uniColors: map.uniColors,
    questionnaireName: map.questionnaireName,
    department: map.department,
    questionTitle: map.questionTitle,
    colorBy: map.colorBy,
    filtersStr: map.filtersStr,
    clickToIsolateNodeId: map.clickToIsolateNodeId,
    edgesFromFilter: map.edgesFromFilter,
    edgesToFilter: map.edgesToFilter,
    showCoreNetwork: map.showCoreNetwork
  }
}
