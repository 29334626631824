import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash'

/**
 * Simple toggler component which enables injecting a set of values from parent and parent is subscribing
 * to the event.
 */
@Component({
  selector: 'sa-app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss']
})
export class TogglerComponent implements OnInit {

  /** The options this widget will manage */
  @Input() options: string[];

  /** The state this widget models */
  @Input() appStateObserver$: Observable<string>

  /** The action this widget uses to update the model */
  @Input() setViewAction: (state: string) => void

  /** The widget's internal options representation */
  myOptions: {name: string, is_selected: boolean}[] = [];

  ngOnInit() {
    this.setInitialOption();

    this.appStateObserver$.subscribe( state => {
      this.setInternalState(state)
    })
  }

  toggled(state: string) {
    this.setInternalState(state)
    this.setViewAction(state)
  }

  setInternalState = (state: string) => {
    this.myOptions.map((opt) => {
      opt.is_selected = (opt.name === state)
    });
  }

  setInitialOption() {
    this.options.map((opt: string) => {
      this.myOptions.push({name: opt, is_selected: false})
    });
  }
}
