﻿import { Component, Input, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash'
import * as util from '../../services/util.service'
import * as Consts from '../../constants'

import { mockEmailAlgoScoresState, mockAlgorithms } from '../../services/mocks.service'
import { IScoreRow, IGroupsState, IGroup } from '../../app.state'

const AGG_GROUP  = 'groupName'
const AGG_OFFICE = 'officeName'
const AGG_ALGO   = 'algoName'

//////////////////////// Model ////////////////////////////
interface IScoresHash {
  [key: string]: IScore
}
interface IScore {
  curScore: number,
  curNum: number,
  prevScore: number,
  prevNum: number,
  groupSize?: number,
  subScores?: IScoresHash
}
type TScoresArr = {
  name: string,
  curScore: number,
  curNum: number,
  prevScore: number,
  prevNum: number,
  groupSize?: number,
  subScores?: TScoresArr,
  scoreBar: number
}[]

const trace = util.traceToggle(false)

//////////////////////// Component ////////////////////////////
@Component({
  selector: 'sa-ematrix',
  templateUrl: './ematrix.component.html',
  styleUrls: ['./ematrix.component.scss']
})
export class EMatrixComponent implements OnInit {

  static readonly causeDescriptions =  {
    'Emails Volume': 'Emails sent and recieved',
    'Spammers': 'Outdegree on TO',
    'Blitzed': 'Indegree on TO',
    'Ccers': 'CC outdegree / total outdegree',
    'Cced': 'CC indegree / total indegree',
    'Deadends': 'ALL indegree / Reply outdegree',
    'Politicos': 'BCC indegree / total indegree',
    'Undercover': 'BCC outdegree / total outdegree',
    'Relays': 'TO which are forward / total number of TO',
  }

  emailScores: TScoresArr
  score: number
  selectedRow: number
  expandedRow: number
  @Input() tabColor: string
  @Input() timeSpentAid: string

  @select(['groups']) readonly groupsTreeState$: Observable<IGroupsState>;
  selectedGroups: number[]

  @select(['global', 'aggregatorType']) readonly aggregatorType$: Observable<string>;
  agg1: string
  agg2: string

  @select(['global', 'showChart']) readonly showChart$: Observable<boolean>;

  @Input() scores$: Observable<IScoreRow[]>
  scoresList: IScoreRow[]




/////////////////////////////// end static methods ////////////////////////////////

  /**
   * @param emailScores - Array of scores
   * @param prmProp - The first property by which to aggregate (department, office or cause)
   * @param sndProp - The second property by which to aggregate (department, office or cause)
   *
   * Takes the scores array and aggregates the score according to the two aggregation types.
   *  - offices and departments only accumulate "Emails Volume".
   *  - causes accumulates everything.
   */
  static aggregateScores(emailScores: IScoreRow[], prmProp: string, sndProp: string, timeSpentAid): IScoresHash {
    trace('EMatrixComponent - aggregateScores(), emailScores: ', emailScores)
    const ires: IScoresHash = {}
    emailScores.forEach(e => {
      const prm: string = e[prmProp]
      if (ires[prm] === undefined) { ires[prm] = {curScore: 0, curNum: 0, prevScore: 0, prevNum: 0, subScores: {}} }
      if (((prmProp === AGG_GROUP || prmProp === AGG_OFFICE) && e.algoName === timeSpentAid) ||
          (prmProp === AGG_ALGO)) {
        trace('Copy scores for primary: ', prmProp, ' with algo: ', e.algoName)
        ires[prm] = EMatrixComponent.copyScores(ires[prm], e)
      }

      const snd: string = e[sndProp]
      if (snd === 'Emails Volume') { return }
      if (ires[prm].subScores[snd] === undefined) { ires[prm].subScores[snd] = {curScore: 0, curNum: 0, prevScore: 0, prevNum: 0} }
      ires[prm].subScores[snd] = EMatrixComponent.copyScores(ires[prm].subScores[snd], e)
    })
    return ires
  }

  static copyScores = (score: IScore, element: IScoreRow): IScore => {
    if (element.curScore !== -1000000.0) {
      score.curScore += element.curScore
      score.curNum += element.curNum
    }

    if (element.prevScore !== -1000000.0) {
      score.prevScore += element.prevScore
      score.prevNum += element.prevNum
    }

    score.groupSize = element.groupSize

    return score
  }

  static convertScoresToArray(ires: IScoresHash): TScoresArr {
    const resArr: TScoresArr = []
    _.forEach(ires, (v, k) => {
      const subScores: TScoresArr = []
       _.forEach(v.subScores, (sv, sk) => {
         subScores.push({
           name: sk,
           curScore: sv.curScore,
           curNum: sv.curNum,
           prevScore: sv.prevScore,
           prevNum: sv.prevNum,
           groupSize: sv.groupSize,
           scoreBar: 0
         })
      })

      resArr.push({
        name: k,
        curScore: v.curScore,
        curNum: v.curNum,
        prevScore: v.prevScore,
        prevNum: v.prevNum,
        subScores: subScores,
        groupSize: v.groupSize,
        scoreBar: 0
      })
    })
    return resArr
  }

  static scoresRenderer(emailScores: IScoreRow[], prmProp: string, sndProp: string, timeSpentAid: string): TScoresArr {
    const ires: IScoresHash = EMatrixComponent.aggregateScores(emailScores, prmProp, sndProp, timeSpentAid)
    const resArr: TScoresArr = EMatrixComponent.convertScoresToArray(ires)

    // Sort the results, both primary as well as secondary list
    let res: TScoresArr = _.orderBy(resArr, (o) => o.curScore , ['desc'])
    _.forEach(res, (prmScore) => {
      prmScore.subScores = _.orderBy(prmScore.subScores, (o) => o.curScore, ['desc'])
    })

    const max = res[0].curScore
    res = _.map(res, (e) => {
      e.scoreBar = Math.round(e.curScore * 40 / max)
      e.subScores = _.map(e.subScores, (f) => {
        f.scoreBar = Math.round(f.curScore * 40 / e.curScore)
        return f
      })
      return e
    })
    return res
  }

  static setAggregatorsBySelection = (aggregatorType: string): string[] => {
    let agg1: string
    let agg2: string

    switch (aggregatorType) {
      case 'Department':
        agg1 = 'groupName'
        agg2 = 'algoName'
        break;
      case 'Offices':
        agg1 = 'officeName'
        agg2 = 'algoName'
        break;
      case 'Causes':
        agg1 = 'algoName'
        agg2 = 'groupName'
        break;

      default:
        throw new Error(`Illegal aggregator type: ${aggregatorType}`)
    }
    return [agg1, agg2]
  }

  static setSelectedGroupsBySelection = (state: IGroupsState): number[] => {
    return _.filter(state.groups, (e: IGroup) => e.isSelected )
            .map((e: IGroup) => e.gid)
  }

/////////////////////////////// Constructor ////////////////////////////////
  constructor() {
    this.score = 40
    this.selectedRow = -1
    this.expandedRow = -1
  }

  ngOnInit() {
    this.groupsTreeState$.subscribe( state => {
      this.selectedGroups = EMatrixComponent.setSelectedGroupsBySelection(state)
    })

    this.aggregatorType$.subscribe( state => {
      [this.agg1, this.agg2] = EMatrixComponent.setAggregatorsBySelection(state)
      if (this.scoresList === undefined) { return }
      this.emailScores = this.calculateScoresPresentation(this.selectedGroups, this.agg1, this.agg2, this.scoresList)
    })

    this.scores$.subscribe( scores => {
      if (scores.length === 0) {return}
      this.scoresList = scores
      this.emailScores = this.calculateScoresPresentation(this.selectedGroups, this.agg1, this.agg2, scores)
    })
  }
/////////////////////////////// Class methods ////////////////////////////////
  calculateScoresPresentation = (selectedGroups: number[], agg1: string, agg2: string, scoresList: IScoreRow[]): TScoresArr => {
    const tempScores = scoresList
    const fitleredTempScores = _.filter(tempScores, (e: IScoreRow) => selectedGroups.indexOf( e.gid ) >= 0 )
    if (fitleredTempScores.length > 0) {
      return EMatrixComponent.scoresRenderer(fitleredTempScores , agg1, agg2, this.timeSpentAid )
    } else {
      return EMatrixComponent.scoresRenderer(tempScores , agg1, agg2, this.timeSpentAid )
    }
  }

  arrowDirectionIsDown = (v): boolean => {
    return util.arrowDirectionIsDown(v)
  }

  arrowDirectionIsUp = (v): boolean => {
    return !util.arrowDirectionIsDown(v)
  }

  getPercentChangedColor = (change: number) => {
    return util.getPercentChangedColor(change)
  }

  rowSelected = (row: number) => {
    if (this.selectedRow === row) {
      this.selectedRow = -1
    } else {
      this.selectedRow = row
    }
  }

  isRowSelected = (row: number): boolean => {
    return (this.selectedRow === row)
  }

  rowExpanded = (row: number): void => {
    if (this.expandedRow === row) {
      this.expandedRow = -1
      this.selectedRow = -1
    } else {
      this.expandedRow = row
      this.selectedRow = row
    }
  }

  isRowExpanded = (row: number): boolean => {
    return (this.expandedRow === row)
  }

  getFontColor = (row) => {
    if (this.selectedRow === row) {
      return this.tabColor
    }
    return Consts.INACTIVE_COLOR
  }

  calculatePercentChange = (curr: number, prev: number): number => {
    return util.round1(100 * ((curr - prev) / prev))
  }

  getDescription = (heading: string): string => {
    trace('EmatrixComponent - In onMouseenter() for: ', heading)
    const desc = EMatrixComponent.causeDescriptions[heading]
    if (desc === undefined) { return heading }
    return desc
  }
}
