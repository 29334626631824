import { Injectable } from '@angular/core'
import { NgRedux } from '@angular-redux/store'

import { ITimeSpentState, IMeetingsState } from '../../app.state'
import { TimeSpentActions } from './timespent.actions'
import { DataService, TgetFunction } from '../../services/data.service'

import * as util from '../../services/util.service'
import { traceToggle } from '../../services/util.service';

const trace = util.traceToggle(false)

@Injectable()
export class MeetingsActions extends TimeSpentActions {

  protected actionPrefix = 'meetings'

  constructor(public ngRedux: NgRedux<IMeetingsState>,
              public ds: DataService) {
    super(ngRedux, ds)
    trace('In MeetingsActions constructor')
  }

  public getTimeSpentVolume(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    trace('MeetingsActions - sending: getMeetingsVolumeScores')
    this.ds.getMeetingsVolumeScores(params, successFunc)
  }

  public getScoresData(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    this.ds.getMeetingsData(params, successFunc)
  }

  public getTimeSpentStats(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    trace('MeetingsActions - sending: getMeetingsStats')
    this.ds.getMeetingsStats(params, successFunc)
  }

  public getEmployeesScores(params: {[key: string]: any}, successFunc: (data: any) => void): void {
    trace('MeetingsActions - sending: getEmployeeScores')
    this.ds.getEmployeesMeetingsScores(params, successFunc)
  }

}
