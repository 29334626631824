﻿import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import * as _ from 'lodash'
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'
import { select } from '@angular-redux/store'

import { InteractActions } from './interact.actions'
import {
  InteractTab,
  InteractQuestTab,
  IQuest,
  IInteractQuestion,
  IInteractParticipant,
  IInteractCollaborationReportRow,
  IGroup
 } from '../../app.state'

import { getActiveGroupsNoneIsAll } from '../../services/groups.service'
import * as util from '../../services/util.service'
import { NavActions } from '../nav/nav.actions'
import { MapActions } from '../map/map.actions'

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-app-int',
  templateUrl: './interact.component.html',
  styleUrls: ['./interact.component.scss']
})
export class InteractComponent implements OnInit, OnDestroy {
  currentTab = ''
  title = 'Explore'
  subtitle = ''
  headingsTitle = 'My Questionnaires'

  questionnaires: IQuest[]
  activeQuest: IQuest
  activeQuestId: number
  activeQuestStats: number[]

  questions: IInteractQuestion[]
  participants: IInteractParticipant[]

  /** Infinite scroll */
  @select(['interact', 'fetchingParticipants']) readonly fetchingParticipants$: Observable<boolean>
  @select(['interact', 'participantsOffset']) readonly participantsOffset$: Observable<number>

  @select(['interact', 'openedTab']) readonly selectedTab$: Observable<InteractTab>
  @select(['interact', 'settingsTab']) readonly settingsTab$: Observable<InteractQuestTab>
  @select(['interact', 'activeQuestId']) readonly activeQuestId$: Observable<number>
  @select(['interact', 'activeQuestionId']) readonly activeQuestionId$: Observable<number>
  @select(['interact', 'questIsOpened']) readonly questIsOpened$: Observable<boolean>
  @select(['interact', 'quests']) readonly quests$: Observable<IQuest[]>
  @select(['interact', 'questions']) readonly questions$: Observable<IInteractQuestion[]>
  @select(['interact', 'participants']) readonly participants$: Observable<IInteractParticipant[]>
  @select(['interact', 'errors']) readonly errors$: Observable<string[]>
  @select(['interact', 'collaborationResults']) readonly collaborationResults$: Observable<IInteractCollaborationReportRow[]>
  @select(['interact', 'collaborationScore']) readonly collaborationScore$: Observable<number>
  @select(['interact', 'synergyScore']) readonly synergyScore$: Observable<number>
  @select(['interact', 'centralityScore']) readonly centralityScore$: Observable<number>
  @select(['interact', 'hideNav']) readonly hideNav$: Observable<boolean>
  @select(['global', 'currentUser', 'user_type']) readonly userRole$: Observable<string>

  @select(['groups', 'groups']) readonly groups$: Observable<IGroup[]>
  selectedGroups: number[]


  groupsSub: Subscription
  settingsTabSub: Subscription
  activeQuestIdSub: Subscription
  questsSub: Subscription
  questionsSub: Subscription
  participantsSub: Subscription
  selectedTabSub: Subscription

  @Output() addRequestEmitter: EventEmitter<number>

  constructor(private interactActions: InteractActions,
              private navActions: NavActions,
              public mapActions: MapActions) {
    this.addRequestEmitter = new EventEmitter()
  }

  ngOnDestroy() {
    this.settingsTabSub.unsubscribe()
    this.activeQuestIdSub.unsubscribe()
    this.questsSub.unsubscribe()
    this.questionsSub.unsubscribe()
    this.participantsSub.unsubscribe()
    this.selectedTabSub.unsubscribe()
    this.groupsSub.unsubscribe()
  }

  ngOnInit() {

    this.groupsSub = this.groups$.debounceTime(1000).subscribe((groups: IGroup[]) => {
      this.selectedGroups = getActiveGroupsNoneIsAll(groups)
    })

    this.settingsTabSub = this.settingsTab$.subscribe( this.updateTitleFromStateChange )

    this.selectedTabSub = this.selectedTab$.subscribe((state: InteractTab) => {
      switch (state) {
        case InteractTab.collaboration:
          this.title = 'Collaboration'
          break
        case InteractTab.explore:
          this.title = 'Explore'
          break
        case InteractTab.settings:
          this.title = 'Settings'
          break
      }
    })

    /**
     * Listen to change in active questionnaire
     */
    this.activeQuestIdSub =  this.activeQuestId$.subscribe((state: number) => {
      if (state === undefined) {return}
      this.activeQuestId = state

      if (this.questionnaires === undefined) {return}

      this.activeQuest =
        _.find(this.questionnaires, (q: IQuest) => {
          return q.id === state
        })

      if (this.activeQuest !== undefined && this.questionnaires.length > 0) {
        this.activeQuestStats = this.activeQuest.stats
        this.navActions.fetchGroups(this.activeQuest.snapshot_id, this.activeQuest.id)
      }
      this.interactActions.fetchQuestions(state)
    })

    /**
     * QUestionnaires list has changed
     */
    this.questsSub = this.quests$.subscribe((state: IQuest[]) => {
      this.questionnaires = state
      if (this.activeQuestId !== undefined) {
        this.activeQuest =
        _.find(this.questionnaires, (q: IQuest) => {
          return q.id === this.activeQuestId
        })
      }

      if (this.activeQuest !== undefined && this.questionnaires.length > 0) {
        this.activeQuestStats = this.activeQuest.stats
        this.navActions.fetchGroups(this.activeQuest.snapshot_id, this.activeQuestId)
      }
    })

    /**
     * Questions list has changed
     */
    this.questionsSub = this.questions$.subscribe((state: IInteractQuestion[]) => {
      this.questions = state
      let qqid = -1
      if (this.questions.length > 0) {
        const activeQuestions = _.filter(this.questions, q => q.active)
        qqid = activeQuestions.length > 0 ? activeQuestions[0].id : -1
      }
      this.interactActions.setActiveQuestion(qqid)
    })

    /**
     * Participants list has changed
     */
    this.participantsSub = this.participants$.subscribe((state: IInteractParticipant[]) => {
      this.participants = state
    })

    this.interactActions.fetchQuestionnaires()
  }

  errorboxClose = () => {
    this.interactActions.errrBoxClose()
  }

  updateTitleFromStateChange = (state: InteractQuestTab) => {
    switch (state) {
      case InteractQuestTab.quest:
        this.headingsTitle = 'Questionnaire'
        break;
      case InteractQuestTab.questions:
        this.headingsTitle = 'Questions'
        break;
      case InteractQuestTab.participants:
        this.headingsTitle = 'Participants'
        break;
      case InteractQuestTab.test:
        this.headingsTitle = 'Verification'
        break;
      case InteractQuestTab.reports:
        this.headingsTitle = 'Reports'
        break;
      default:
      this.headingsTitle = 'My Questionnaires'
        break;
    }
  }

  menuItemClicked(m) {
    this.interactActions.menuItemClicked(m)
  }

  addClicked() {
    this.addRequestEmitter.emit()
  }

  questionnaireReport() {
    this.interactActions.downloadQuestionnaireReport(this.activeQuestId)
  }

  bidirectionalReport() {
    this.interactActions.downloadBidirectionalReport(this.activeQuestId)
  }

  scoresReport() {
    this.interactActions.scoresReport(this.activeQuestId)
  }

  moreParticipants = (offset: number) => {
    console.log('InteractComponent - In moreParticipants with offset: ', offset)
    this.interactActions.fetchParticipants(this.activeQuest.id, offset )
  }

  toggleNav = () => {
    this.interactActions.mapNavToggle()
  }
}
