import {
  Component,
  Input,
  OnInit
} from '@angular/core'
import { select, NgRedux } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash'

import { MapActions } from './map.actions'
import {
  IQuest,
  IInteractQuestion,
  IMapData,
  IFilter,
  TFilterArr,
  IAppState
} from '../../app.state'
import * as util from '../../services/util.service'
import * as Texts from '../../texts'
import { COLORSHEX } from '../../constants'

import { InteractActions } from '../interact/interact.actions'

const trace = util.traceToggle(false)

@Component({
  selector: 'sa-app-int-map-nav',
  templateUrl: './map-nav.component.html',
  styleUrls: ['./map-nav.component.scss']
})
export class MapNavComponent implements OnInit {

  subtitle = Texts.INTERACT_EXPLORE_SUBTITLE
  searchValue: string

  /** isFloater */
  @Input() isFloater: boolean
  @Input() mapRootId: string

  /** questionnaires */
  questsListOpen = false
  selectedQuestName: string
  questionnairesList: IQuest[]
  @Input() activeQuestId: number | 'do-not-show'
  @Input() set questionnaires(v: IQuest[] | 'do-not-show') {
    if (v === 'do-not-show') {return}
    this.selectedQuestName = this.updateSelectedQuestName(v, this.activeQuestId)
    this.questionnairesList = v
  }

  /** quesitons */
  questionsListOpen = false
  selectedQuestionName: string
  questionsList: IInteractQuestion[]
  @Input() activeQuestionId: number | 'do-not-show'
  @Input() set questions(v: IInteractQuestion[] | 'do-not-show') {
    if (v === 'do-not-show') {return}
    this.selectedQuestionName = this.updateSelectedQuestionName(v, this.activeQuestionId)
    this.questionsList = _.filter(v, (qest: IInteractQuestion) => qest.active )
  }

  @Input() activeGids: number[]

  /** Color By */
  colorbyOpen = false
  colorbyList = ['Structure', 'Role', 'Rank', 'Gender', 'Formal', 'Office']
  selectedColorby = 'Structure'

  /** Filters */
  filtersOpen = false
  selectedFilters = 'Default'
  nodes$: Observable<IMapData[]>
  filters$: Observable<IFilter[]>
  nodes: IMapData[]
  filters: IFilter[]

  /** Edges Filter */
  edgesFilterOpen = false
  edgesFromFilter$: Observable<number>
  edgesToFilter$: Observable<number>
  showCoreNetwork$: Observable<boolean>

  /** Hide names */
  hideNamesOpen = false
  hideNames$: Observable<boolean>

  /** Uni colors */
  uniColors$: Observable<boolean>

  // ========================= Static functions ========================================== //
  /**
   * Translate a list of nodes to the possible filters.
   * Randomly assign colors
   */
  static nodesToFitlers = (nodes: IMapData[], filt: IFilter[]): IFilter[] => {
    _.forEach(nodes, (n: IMapData) => {
      const role = n.role
      if (role !== undefined) {
        if (_.find(filt[0].values, (e: {name: string, active: boolean}) => e.name === role) === undefined ) {
          filt[0].values.push({name: role, active: false, color: Math.round( Math.random() * 16)})
        }
      }

      const office = n.office_name
      if (office !== undefined) {
        if (_.find(filt[3].values, (e: {name: string, active: boolean}) => e.name === office) === undefined ) {
          filt[3].values.push({name: office, active: false, color: Math.round( Math.random() * 16)})
        }
      }

      const jobTitle = n.job_title
      if (jobTitle !== undefined) {
        if (_.find(filt[4].values, (e: {name: string, active: boolean}) => e.name === jobTitle) === undefined ) {
          filt[4].values.push({name: jobTitle, active: false, color: Math.round( Math.random() * 16)})
        }
      }

      const group = n.group_name
      if (group !== undefined) {
        if (_.find(filt[6].values, (e: {name: string, active: boolean}) => e.name === group) === undefined ) {
          filt[6].values.push({name: group, active: false, color: n.color_id})
        }
      }
    })
    return filt
  }

  /**
   * Translate fitlers to string representation
   */
  static filtersToString = (filters: IFilter[]): string => {
    const filterSnipets = _.map(filters, (f) => MapNavComponent.filterToString(f))
                           .filter((f) => f !== '')
    if (filterSnipets.length === 0) { return 'Default'}
    return filterSnipets.join(' > ')
  }
  static filterToString = (filt: IFilter): string => {
    let resArr: any = _.filter(filt.values, (f) => f.active)
    if (resArr.length === 0) { return '' }
    resArr = _.map(resArr, (f: {name: string, active: boolean}) => f.name )
    const stringRep = `${filt.name}: ${resArr.join(',')}`
    return stringRep
  }

  /**
   * Flatten the structure into a list of active values only.
   * Result example: [{filterName: 'Role', filterValue: 'Manager'},
   *                  {filterName: 'Age', filterValue: '41-50'}]
   */
  static filtersFlatten = (filters: IFilter[]): TFilterArr => {
    const filterSnipets = _.flatMap(filters, (filt) => {
      return _.filter(filt.values, (val) => val.active )
              .map((val) => ( {filterName: filt.name, filterValue: val.name} ) )
    })
    return filterSnipets
  }

  /**
   * filter a bunch of nodes using the data structure return from fitlersFlatten above. Use this
   * function to determine which nodes should be greyed out
   */
  static filterNodes = (filters: IFilter[], nodes: IMapData[]): IMapData[] => {
    const flatFilters = MapNavComponent.filtersFlatten(filters)
    const newNodes =  _.map(nodes, (n: IMapData) => {
      n.fi = !MapNavComponent.isNodeVisible(flatFilters, n)
      return n
    })
    return newNodes
  }
  /**
   * A node is visible only if it passes all filters.
   */
  static isNodeVisible = (filters: TFilterArr, node: IMapData): boolean => {
    return _.reduce(filters, (preres, filt) => {
      let res = false
      switch (filt.filterName) {
        case 'Role':
          res = (filt.filterValue === node.role) && preres
          break
        case 'Rank':
          res =  (filt.filterValue === node.rank.toString()) && preres
          break
        case 'Gender':
          res =  (filt.filterValue === node.gender) && preres
          break
        case 'Office':
          res =  (filt.filterValue === node.office_name) && preres
          break
        case 'Job Title':
          res =  (filt.filterValue === node.job_title) && preres
          break
        case 'Age':
          res =  (filt.filterValue === node.age.toString()) && preres
          break
          case 'Structure':
          res =  (filt.filterValue === node.group_name) && preres
          break
      }
      return res
    }, true)
  }
  // ========================= Statics end ========================================== //

  constructor(private ma: MapActions,
              private interactActions: InteractActions,
              private ngRedux: NgRedux<IAppState>
    ) {}

  ngOnInit() {
    this.nodes$ = this.ngRedux.select([this.mapRootId, 'nodes'])
    this.filters$ = this.ngRedux.select([this.mapRootId, 'filters'])
    this.edgesFromFilter$ = this.ngRedux.select([this.mapRootId, 'edgesFromFilter'])
    this.edgesToFilter$ = this.ngRedux.select([this.mapRootId, 'edgesToFilter'])
    this.showCoreNetwork$ = this.ngRedux.select([this.mapRootId, 'showCoreNetwork'])
    this.hideNames$ = this.ngRedux.select([this.mapRootId, 'hideNames'])
    this.uniColors$ = this.ngRedux.select([this.mapRootId, 'uniColors'])

    this.nodes$.subscribe((v) => {
      if (v === undefined || v.length === 0) {return}
      this.nodes = v
      this.ma.updateFitlers(this.mapRootId)
    })

    this.filters$.subscribe((f) => {
      this.filters = f
    })

    if (this.isFloater) {
      this.addDragFunctionalityToMapNav( this.mapRootId )
    }
  }

  questClicked = (qid: number) => {
    this.selectedQuestName = this.updateSelectedQuestName(this.questionnairesList, qid)
    this.interactActions.questSelected(qid)
  }

  questionClicked = (qqid: number) => {
    this.interactActions.setActiveQuestion(qqid)
    this.ma.fetchMapData(this.mapRootId, qqid, this.activeGids)
    const quest = _.filter(this.questionsList, (q) => q.id === qqid)
    if (!_.isNil(quest) && quest.length > 0) {
      this.selectedQuestionName = quest[0].title
    } else {
      this.selectedQuestionName = 'NA'
    }
  }

  updateSelectedQuestName = (quests: IQuest[], qid ): string => {
    if (quests.length === 0) { return 'NA' }
    const selectedQuest: IQuest =
      _.find(quests, (q: IQuest) => q.id === qid)
    if (this.activeQuestId === -1 ||
        selectedQuest === undefined) {
      return quests[0].name
    }
    return selectedQuest.name
  }

  updateSelectedQuestionName = (quests: IInteractQuestion[], qid ): string => {
    if (quests.length === 0) { return 'NA' }
    const selectedQuestion: IInteractQuestion =
      _.find(quests, (q: IInteractQuestion) => q.id === qid)
    if (this.activeQuestionId === -1 || selectedQuestion === undefined) {
      const activeQuests = _.filter(quests, q => q.active)
      return (activeQuests.length > 0 ? activeQuests[0].title : 'NA')
    }
    return selectedQuestion.title
  }

  colorbyClicked = (colorby: string) => {
    trace('In colorbyClicked() - colorby: ', colorby)
    this.selectedColorby = colorby
    this.ma.colorbySelected(this.mapRootId, colorby, this.filters)
  }

  filterToggled = (inx: number) => {
    this.filters[inx].open = !this.filters[inx].open
  }

  filterItemToggled = (inx: number, finx: number) => {
    this.filters[inx].values[finx].active = !this.filters[inx].values[finx].active
    this.selectedFilters = MapNavComponent.filtersToString(this.filters)
    this.ma.reColorMap(this.mapRootId)
  }

  hexColor = (colorInx: number) => {
    return COLORSHEX[colorInx]
  }
  searchTriggered = (event) => {
    const searchText = event.target.value
    this.ma.searchNode(this.mapRootId, searchText)
  }

  clearSearch = () => {
    const search = document.getElementById('int-map-search-line-id')
    this.searchValue = ''
    this.ma.searchNode(this.mapRootId, '')
  }

  groupAll = () => {
    this.ma.groupAll(this.mapRootId)
  }

  toggleHideNames = ($event) => {
    this.ma.toggleHideShowNames(this.mapRootId, $event)
  }

  toggleUniColors = ($event) => {
    this.ma.toggleUniColors(this.mapRootId, $event)
  }

  edgeFromFilterChanged = ($event) => {
    this.ma.changeEdgesFilter(this.mapRootId, $event, 'from')
  }

  edgeToFilterChanged = ($event) => {
    this.ma.changeEdgesFilter(this.mapRootId, $event, 'to')
  }

  toggleCoreNetwork = () => {
    this.ma.toggleCoreNetwork(this.mapRootId)
  }

  /**
   * Make the explore filter floater div draggable.
   */
  addDragFunctionalityToMapNav = (mapRootId: string) => {

    const elmnt = document.getElementById( `${mapRootId}-nav-floater` )
    if (_.isNil(elmnt)) {return}

    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0
    document.getElementById( `${mapRootId}-nav-floater-header` ).onmousedown = dragMouseDown

    function dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      // get the mouse cursor position at startup:
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag
    }

    function elementDrag(e) {
      e = e || window.event
      e.preventDefault()
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + 'px'
      elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px'
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null
      document.onmousemove = null
    }
  }
}
