import { Component, Input} from '@angular/core';

@Component({
  selector: 'sa-leaderboard-collaboration-header',
  templateUrl: './leaderboard-collaboration-header.component.html',
  styleUrls: ['./leaderboard-collaboration-header.component.scss']
})
export class LeaderboardCollaborationHeaderComponent {

  @Input() leftValue: number
  @Input() leftColor: string
  @Input() leftTopCaption: string
  @Input() leftDescription: string
  @Input() leftBottomCaption: string

  @Input() rightValue: number
  @Input() rightColor: string
  @Input() rightTopCaption: string
  @Input() rightDescription: string
  @Input() rightBottomCaption: string

  constructor() {}
}
