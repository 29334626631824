import * as _ from 'lodash'

import { IAlertAction, AlertActions } from './alerts.actions';
import * as util from '../../services/util.service'
import { IAlertsState, INITIAL_STATE_ALERTS } from '../../app.state';

const trace = util.traceToggle(false)

export function alertsReducer(lastState: IAlertsState, action: IAlertAction): IAlertsState {
  if (lastState === undefined) { return INITIAL_STATE_ALERTS }

  switch (action.type) {
    case AlertActions.FETCH_ALERTS: {
      trace('In alertsReducer() - FETCH_ALERTS')
      return lastState
    }
    case AlertActions.FETCH_ALERTS_SUCCESS: {
      trace('In alertsReducer() - FETCH_ALERTS_SUCCESS, alerts: ', action.alerts)
      if (action.alerts === undefined) { return lastState }
      const newState: IAlertsState =  Object.assign({}, lastState, {alerts: action.alerts})
      newState.numberDisplayed = action.alerts.length
      return newState
    }
    case AlertActions.FETCH_ALERTS_ERROR: {
      console.error('alertsReducer() - Error fetching alerts')
      return lastState
    }

    case AlertActions.ALERT_ACKNOWLEDGE: {
      const alid = action.alid
      trace('In alertsReducer() - ALERT_ACKNOWLEDGE, alert id: ', alid)
      if (alid <= 0) { return lastState }
      const alerts = _.cloneDeep(lastState.alerts)
      const alert = _.find(alerts, a => a.alid === alid)
      alert.state = 'viewed'
      const newState = Object.assign({}, lastState, {alerts: alerts})
      newState.numberDisplayed = lastState.numberDisplayed - 1
      return newState
    }

    default:
      return lastState
  }
}

