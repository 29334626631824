﻿import { EmailsComponent } from './components/timespent/emails.component'
import { MeetingsComponent } from './components/timespent/meetings.component'
import { DynamicsComponent } from './components/dynamics/dynamics.component'
import { InterfacesComponent } from './components/interfaces/interfaces.component'
import { SimulatorComponent } from './components/simulator/simulator.component'
import { LoginComponent } from './components/login/login.component'
import { SettingsComponent } from './components/settings/settings.component'
import { InteractComponent } from './components/interact/interact.component'

export const ROUTER_ROUTE_CHANGED = '@angular-redux/router::UPDATE_LOCATION'

export const routes = [
  { path: '', redirectTo: '/emails', pathMatch: 'full' },
  { path: 'emails', component: EmailsComponent },
  { path: 'meetings', component: MeetingsComponent },
  { path: 'dynamics', component: DynamicsComponent },
  { path: 'interfaces', component: InterfacesComponent },
  { path: 'simulator', component: SimulatorComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'interact', component: InteractComponent},
  { path: 'login', component: LoginComponent }
];
