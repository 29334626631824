import { Component, Input, OnInit} from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';

import * as U from '../../services/util.service'


@Component({
  selector: 'sa-leaderboard-header',
  templateUrl: './leaderboard-header.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardHeaderComponent {

  @Input() tabColor: string
  @Input() leftValue: number
  @Input() rightValue: number

  constructor() {}
}
