import * as _ from 'lodash'

import * as util from '../../services/util.service'
import { ITimeSpentAction, TimeSpentActions } from './timespent.actions';
import { EmailsActions } from './emails.actions';
import { MeetingsActions } from './meetings.actions';
import {
  ITimeSpentState,
  INITIAL_STATE_TIME_SPENT,
  ISizeWithDiff,
  ITimeSpentStats,
  IEmailsState,
  IMeetingsState,
  INITIAL_STATE_MEETINGS,
  INITIAL_STATE_EMAILS
} from '../../app.state'

const trace = util.traceToggle(false);

export const meetingsReducer = (lastState: IMeetingsState, action: ITimeSpentAction): IMeetingsState => {
  if (lastState === undefined) { return INITIAL_STATE_MEETINGS}
  const actionPrefix = action.type.split('-')[0]
  if (actionPrefix !== 'meetings') { return lastState }
  trace('MEETINGS reducer - ', action.type)
  const state = timeSpentReducer(lastState, action)
  return Object.assign({}, state, {isEmail: false, isMeetings: true})
}

export const emailsReducer = (lastState: IEmailsState, action: ITimeSpentAction): IEmailsState => {
  if (lastState === undefined) { return INITIAL_STATE_EMAILS}
  const actionPrefix = action.type.split('-')[0]
  if (actionPrefix !== 'emails') { return lastState }
  trace('EMAILS reducer', action)
  const state = timeSpentReducer(lastState, action)
  return Object.assign({}, state, {isEmail: true, isMeetings: false})
}

export const timeSpentReducer = (lastState: ITimeSpentState, action: ITimeSpentAction): ITimeSpentState => {
  if (lastState === undefined) { return INITIAL_STATE_TIME_SPENT}

  // Doing this here because action names have to be distinct in order for Redux to dispatch
  // them to the correct reducer
  const baseActionType = action.type.split('-')[1]
  switch (baseActionType) {
    case TimeSpentActions.FETCH_TIME_SPENT:
      trace('TimeSpentActions.FETCH_TIME_SPENT')
      return lastState;
    case TimeSpentActions.FETCH_TIME_SPENT_SUCCESS: {
      trace('TimeSpentActions.FETCH_TIME_SPENT_SUCCESS')
      return Object.assign({}, lastState, {scores: action.scoresData});
    }
    case TimeSpentActions.FETCH_TIME_SPENT_VOLUME:
      trace('TimeSpentActions.FETCH_TIME_SPENT_VOLUME')
      return lastState;
    case TimeSpentActions.FETCH_TIME_SPENT_VOLUME_SUCCESS: {
      trace('TimeSpentActions.FETCH_TIME_SPENT_VOLUME_SUCCESS')
      return Object.assign({}, lastState, {timePickerData: action.timePickerData});
    }
    case TimeSpentActions.FETCH_TIME_SPENT_FAIL:
      trace('TimeSpentActions.FETCH_EMAILS_FAIL')
      return lastState;
    case TimeSpentActions.FETCH_EMPLOYEES:
      trace('TimeSpentActions.FETCH_EMPLOYEES')
      return lastState;
    case TimeSpentActions.FETCH_EMPLOYEES_SUCCESS: {
      trace('TimeSpentActions.FETCH_EMPLOYEES_SUCCESS')
      return Object.assign({}, lastState, {
        employeeScores: action.employeesData['top_scores'],
        averageTimeSpent: action.employeesData['avg_per_emp']
      });
    }
    case TimeSpentActions.FETCH_EMPLOYEES_FAILE:
      trace('TimeSpentActions.FETCH_EMPLOYEES_FAILE')
      return lastState;
    case TimeSpentActions.FETCH_TIME_SPENT_STATS:
      return lastState
    case TimeSpentActions.FETCH_TIME_SPENT_STATS_SUCCESS:
      const totalTimeSpent: ISizeWithDiff = {size: action.stats.total_time_spent, diff: action.stats.total_time_spent_diff}
      const averageTimeSpent:   ISizeWithDiff = {size: action.stats.num_quantity_avg, diff: action.stats.num_quantity_diff}
      const timeSpent: ITimeSpentStats = {totalTimeSpent: totalTimeSpent, averageTimeSpent: averageTimeSpent}
      return Object.assign({}, lastState, {timeSpent: timeSpent});
    default:
      return lastState;
  }
}
