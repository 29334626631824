import * as _ from 'lodash'

import { IMapData } from '../app.state'

interface IIndex {
  [id: string]: any
}

export const createDynamicsLinksIndex = (mapData: IMapData[]): IIndex => {
  const links = _.filter(mapData, (e: IMapData) => e.type === 'link')
  const linksInx: IIndex = {}
  _.forEach(links, (l: IMapData) => {
    linksInx[l.id] = l
  })
  return linksInx
}

/**
 * place all weights in buckts of weight 1 to 10
 */
export const normalizeLinks = (links: IMapData[], displayLinkWeight: boolean = false): IMapData[] => {
  if (links.length === 0) { return links }
  const max = _.maxBy(links, 'w').w
  const ret = _.map(links, (l: IMapData) => {
    const tmp = (l.w / max) * 100
    l.w = (Math.floor(tmp / 10) + 1)

    if (displayLinkWeight) { l.t = l.w.toString() }
    return l
  })
  return ret
}

/**
 * Will normalized each score to an enlargemnet factor between 0.5 and 2
 */
export const normalizeNodes = (nodes: IMapData[]): IMapData[] => {
  if (nodes.length === 0) { return nodes }
  const max = _.maxBy(nodes, 'e').e
  const min = _.minBy(nodes, 'e').e
  const ret = _.map(nodes, (n: IMapData) => {
    const f: number = n.e
    n.e = 0.75 + (1.25 * (f - min) / (max - min))
    return n
  })
  return ret
}

/**
 * Unify two links that go both sides with same weight
 */
export const unifyLinks = (links: IMapData[]): IMapData[] => {
  const visitedLinksHash = {}
  let source: string
  let target: string
  let reciprocalLink: IMapData

  _.forEach( links, (l: IMapData) => {
    source = l.id1
    target = l.id2
    reciprocalLink = visitedLinksHash[`${target}-${source}`]
    if (reciprocalLink !== undefined && reciprocalLink.w === l.w) {
      reciprocalLink.a1 = true
      return
    }
    visitedLinksHash[`${source}-${target}`] = l
  })

  return _.map(visitedLinksHash, (v: IMapData) => v)
}

export const colorLinks = (links: IMapData[], eid: string): IMapData[] => {
  const target_emp_id = parseInt(eid, 0)
    return _.map(links, l => {
    let color = '#aaaaaa'
    const lid1 = parseInt(l.id1, 0)
    const lid2 = parseInt(l.id2, 0)
    if (lid1 === target_emp_id || lid2 === target_emp_id) { color = '#404347'}
    l.c = color
    return l
  })
}
