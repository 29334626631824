import { Directive, ElementRef, HostListener, Input } from '@angular/core';

const ACTION_TRIGGER_RATIO = 0.9
const ACTION_PAGE_SIZE = 20

@Directive({
  selector: '[appInfScroll]'
})

/**
 * Provides infinite scroll on a DOM element.
 * Requires two state variables actioFlag and actionOffset (See
 * details below) and an action to trigger when nearing the end
 * of the scroll.
 */
export class InfScrollDirective {

  /** What function to call once nearing the end of the scroll */
  @Input() pageAction: (offset: number) => void

  /**
   * If actionIsInProgress is a long async call (like AJAX) then this flag is
   * an indication that the action has been completed
   */
  @Input() actionIsInProgress: boolean

  /**
   * Most infinite scroll scenarios display pages from a database table.
   * actionOffset is a counter for that
   */
  @Input() actionOffset: number

  constructor(private el: ElementRef) { }

  @HostListener('scroll') onscroll() {

    const clientHeight = this.el.nativeElement.clientHeight
    const scrollHeight = this.el.nativeElement.scrollHeight
    const scrollTop = this.el.nativeElement.scrollTop
    const scrollRatio = (scrollTop + clientHeight) / scrollHeight

    if (scrollRatio < ACTION_TRIGGER_RATIO) { return }
    if (this.actionIsInProgress) { return }
    this.pageAction(this.actionOffset + ACTION_PAGE_SIZE)
  }
}
