/**
 * Courtesy of: https://codewithstyle.info/advanced-functional-programming-in-typescript-maybe-monad
 */
export class Maybe<T> {

  static some<T>(value: T) {
    if (!value) {
      throw Error('Provided value must not be empty')
    }
    return new Maybe(value)
  }

  static none<T>() {
    return new Maybe<T>(null)
  }

  static fromValue<T>(value: T) {
    if (value instanceof Array) {
      value.length > 0 ? Maybe.some(value) : Maybe.none<T>()
    }
    return value ? Maybe.some(value) : Maybe.none<T>()
  }

  private constructor(private value: T | null) {}


  map<R>(f: (wrapped: T) => R): Maybe<R> {
    if (this.value instanceof Array) {
      if (this.value.length === 0) {
        return Maybe.none<R>()
      }
    }
    if (this.value === null) {
      return Maybe.none<R>()
    } else {
      return Maybe.some(f(this.value))
    }
  }

  flatMap<R>(f: (wrapped: T) => Maybe<R>): Maybe<R> {
    if (this.value === null) {
      return Maybe.none<R>()
    } else {
      return f(this.value)
    }
  }

  getOrElse(defaultValue: T) {
    return this.value === null ? defaultValue : this.value
  }
}
