import * as _ from 'lodash'
import { IGroup, IMapGroup } from '../app.state'

export const getActiveGroups = (groups: IGroup[]): number[] => {
  if (groups === undefined || groups === null || groups === []) { return [] }
  return _.filter(groups, (g: IGroup) => g.isSelected === true )
          .map((g: IGroup) => g.gid)
}

/**
 * Very similiar to getActiveGroups, but interprets an empty select as all
 * are selected.
 */
export const getActiveGroupsNoneIsAll = (groups: IGroup[]): number[] => {
  let gids = getActiveGroups(groups)
  if (gids === []) {
    gids = _.map(groups, (g: IGroup) => g.gid)
  }
  return gids
}

export const getRootGroupFromMapData = (groups: IMapGroup[]): IMapGroup => {
  return _.find(groups, (g) => g.parentId === null)
}

export const getRootGroup = (groups: IGroup[]): IGroup => {
  return _.find(groups, (g: IGroup) => g.parentId === null)
}
