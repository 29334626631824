import * as _ from 'lodash'
import * as moment from 'moment'

import {
  IInteractState,
  InteractQuestTab,
  INITIAL_STATE_INTERACT,
  IQuest,
  InteractQuestState,
  InteractTab,
  IInteractQuestion,
  IInteractCollaborationReportRow
} from '../../app.state'
import {
  IInteractAction,
  InteractActions
} from './interact.actions'
import { questionnaireStateToEnum } from './interact.util'
import { Maybe } from '../../types/maybe'

import * as util from '../../services/util.service'
import { CurrencyPipe } from '@angular/common';

const trace = util.traceToggle(false)

export function interactReducer(lastState: IInteractState, action: IInteractAction): IInteractState {
  if (lastState === undefined) { return INITIAL_STATE_INTERACT }

  switch (action.type) {
    case InteractActions.INTERACT_MENU_ITEM_CLICKED: {
      return Object.assign({}, lastState, {openedTab: action.menuItem})
    }
    case InteractActions.INTERACT_MGMT_MENU_CLICKED: {
      return Object.assign({}, lastState, {settingsTab: action.mgmtMenuItem})
    }

    case InteractActions.INTERACT_MGMT_ERRORBOX_CLOSE: {
      return Object.assign({}, lastState, {errors: [] })
    }

    case InteractActions.INTERACT_MGMT_QUEST_SELECTED: {
      return Object.assign({}, lastState, {activeQuestId: action.selectedQuestId })
    }
    case InteractActions.INTERACT_MGMT_QUEST_OPENED: {
      return Object.assign(
        {},
        lastState,
        {activeQuestId: action.selectedQuestId,
         questIsOpened: true,
         settingsTab: InteractQuestTab.quest,
         participants: [],
         participantsOffset: 0
        }
      )
    }
    case InteractActions.INTERACT_MGMT_QUEST_CLOSE: {
      return Object.assign(
        {},
        lastState,
        {activeQuestId: -1,
         questIsOpened: false,
         settingsTab: InteractQuestTab.quests}
      )
    }

    /** Fetch questionnaire */
    case InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES: { return lastState }
    case InteractActions.INTERACT_MGMT_FETCH_QUESTIONNAIRES_SUCCESS: {
      const nQuests = addDataFieldsToQuestionnaire(action.questionnaires)
      const firstQuest = Maybe.fromValue(action.questionnaires)
                              .map( quests => quests.length > 0 ? quests[0] : null)
                              .map( quest => quest.id)
                              .getOrElse(-1)
      return Object.assign({}, lastState, {quests: nQuests, activeQuestId: firstQuest })
    }

    /** Add new questionnaire */
    case InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE: { return lastState }
    case InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_NEW_QUESTIONAIRE_SUCCESS: {
      const nQuests = addDataFieldsToQuestionnaire(action.questionnaires)
      return Object.assign({}, lastState, {quests: nQuests })
    }

    /** Copy questionnaire */
    case InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE: { return lastState }
    case InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_COPY_QUESITONNAIRE_SUCCESS: {
      const nQuests = addDataFieldsToQuestionnaire(action.questionnaires)
      return Object.assign({}, lastState, {quests: nQuests })
    }

    /** Delete questionnaire */
    case InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE: { return lastState }
    case InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_DELETE_QUESTIONAIRE_SUCCESS: {
      const nQuests = addDataFieldsToQuestionnaire(action.questionnaires)
      return Object.assign({}, lastState, {quests: nQuests })
    }

    /** Edit questionnaire */
    case InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE: { return lastState }
    case InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE_FAIL: {
      return hendleErrors(lastState, action)
    }
    case InteractActions.INTERACT_MGMT_UPDATE_QUESTIONAIRE_SUCCESS: {
      const quest = action.questionnaire
      const quests = action.questionnaires

      const prevqid = quest.id
      const prevState = _.find(lastState.quests, q => q.id === prevqid).state
      const currState = _.find(quests, q => q.id === prevqid).state

      const newState = Object.assign({}, lastState, {quests: action.questionnaires})

      if (prevState === InteractQuestState.created &&
          currState === InteractQuestState.delivery_method_ready) {
        newState.settingsTab = InteractQuestTab.questions
      }
      return newState
    }

    /** Fetch questions */
    case InteractActions.INTERACT_MGMT_FETCH_QUESTIONS: { return lastState }
    case InteractActions.INTERACT_MGMT_FETCH_QUESTIONS_SUCCESS: {
      let firstQuestionId = -1
      if (action.questions.length > 0) {
        const activeQuestions = _.filter(action.questions, q => q.active)
        firstQuestionId = activeQuestions.length > 0 ? activeQuestions[0].id : -1
      }
      return Object.assign({}, lastState, {questions: action.questions, activeQuestionId: firstQuestionId})
    }

    /** Edit questionnaire */
    case InteractActions.INTERACT_MGMT_UPDATE_QUESTION: { return lastState }
    case InteractActions.INTERACT_MGMT_UPDATE_QUESTION_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_UPDATE_QUESTION_SUCCESS: {
      const nQuestid = action.questionnaire.id
      console.log('action.questionnaire: ', action.questionnaire)
      const nQuestState = action.questionnaire.state
      const quests = _.cloneDeep(lastState.quests)
      const quest = _.find(quests, q => q.id === nQuestid)
      quest.state = nQuestState
      const newState = Object.assign({}, lastState, {quests: quests})
      return newState
    }

    /** Delete question */
    case InteractActions.INTERACT_MGMT_DELETE_QUESTION: { return lastState }
    case InteractActions.INTERACT_MGMT_DELETE_QUESTION_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_DELETE_QUESTION_SUCCESS: {
      return Object.assign({}, lastState, {questions: action.questions })
    }

    /** Reorder questions */
    case InteractActions.INTERACT_MGMT_REORDER_QUESTIONS: { return lastState }
    case InteractActions.INTERACT_MGMT_REORDER_QUESTIONS_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_REORDER_QUESTIONS_SUCCESS: { return lastState }

    /** Fetch particiapnts */
    case InteractActions.INTERACT_MGMT_FETCH_PARTICIPANTS: {
      return Object.assign({}, lastState, {
        fetchingParticipants: true,
        participantsOffset: action.participantsOffset})
    }
    case InteractActions.INTERACT_MGMT_FETCH_PARTICIPANTS_FAIL: {
      return Object.assign({}, lastState, {fetchingParticipants: false})
    }
    case InteractActions.INTERACT_MGMT_FETCH_PARTICIPANTS_SUCCESS: {
      const participants = lastState.participants.concat(action.participants)
      return Object.assign({}, lastState, {
        participants: participants,
        fetchingParticipants: false
      })
    }

    /** Clear participants */
    case InteractActions.INTERACT_MGMT_CLEAR_PARTICIPANTS: {
      console.log('CLEARING')
      return Object.assign({}, lastState, {participants: []})
    }

    /** Set the search string for participants */
    case InteractActions.INTERACT_MGMT_PARTICIPANTS_SET_SEARCH_TEXT: {
      console.log('Setting search string: ', action.searchText)
      return Object.assign({}, lastState, {participantsSearchText: action.searchText})
    }

    /** Edit questionnaire */
    case InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT: { return lastState }
    case InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_UPDATE_PARTICIPANT_SUCCESS: {
      const questid = action.questionnaire.id
      const questState = action.questionnaire.state
      const quests = lastState.quests
      const quest = _.find(quests, q => q.id === questid)
      quest.state = questionnaireStateToEnum(questState)
      const newState = Object.assign({}, lastState, {quests: quests, participants: action.participants})
      return newState
    }

    /** New questionnaire */
    case InteractActions.INTERACT_MGMT_NEW_PARTICIPANT: { return lastState }
    case InteractActions.INTERACT_MGMT_NEW_PARTICIPANT_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_NEW_PARTICIPANT_SUCCESS: {
      const questid = action.questionnaire.id
      const questState = action.questionnaire.state
      const quests = lastState.quests
      const quest = _.find(quests, q => q.id === questid)
      quest.state = questionnaireStateToEnum(questState)
      let newState = Object.assign({}, lastState, {quests: quests})
      newState = Object.assign({}, lastState, {participants: action.participants})
      return newState
    }

    /** Delete question */
    case InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT: { return lastState }
    case InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_DELETE_PARTICIPANT_SUCCESS: {
      const newState = replaceQuestionnaireInState(lastState, action.questionnaire)
      return Object.assign({}, newState, {participants: action.participants })
    }

    /** Upload participants */
    case InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS: { return lastState }
    case InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_UPLOAD_PARTICIPANTS_SUCCESS: {
      const newState = replaceQuestionnaireInState(lastState, action.questionnaire)
      return Object.assign({}, newState, {participants: action.participants })
    }

    /** Upload photo */
    case InteractActions.INTERACT_MGMT_UPLOAD_PHOTO: { return lastState }
    case InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_FAIL: {
      return hendleErrors(lastState, action)
    }
    case InteractActions.INTERACT_MGMT_UPLOAD_PHOTO_SUCCESS: {
      return Object.assign({}, lastState, {participants: action.participants })
    }

    /** Update test participant */
    case InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT: { return lastState }
    case InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_UPDATE_TEST_PARTICIPANT_SUCCESS: {
      const newQuest = action.questionnaire
      newQuest.test_user_url = action.testUserUrl
      const qid = newQuest.id
      const quests = _.cloneDeep(lastState.quests)
      _.remove (quests, q => q.id === qid)
      quests.push(newQuest)
      const newState = Object.assign({}, lastState, {quests: quests})
      return newState
    }

    /** Run questionnaire */
    case InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE: { return lastState }
    case InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_RUN_QUESITONNAIRE_SUCCESS: {
      return replaceQuestionnaireInState(lastState, action.questionnaire)
    }

    /** Close questionnaire */
    case InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE: { return lastState }
    case InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE_FAIL: { return lastState }
    case InteractActions.INTERACT_MGMT_CLOSE_QUESITONNAIRE_SUCCESS: {
      return replaceQuestionnaireInState(lastState, action.questionnaire)
    }

    case InteractActions.INTERACT_MGMT_SET_ACTIVE_QUESTION: {
      return Object.assign({}, lastState, {activeQuestionId: action.qqid})
    }

    /** Fetch question results */
    case InteractActions.INTERACT_COLL_FETCH_QUEST_DATA: { return lastState }
    case InteractActions.INTERACT_COLL_FETCH_QUEST_DATA_FAIL: { return lastState }
    case InteractActions.INTERACT_COLL_FETCH_QUEST_DATA_SUCCESS: {
      const report: IInteractCollaborationReportRow[] = _.map(action.collaboration_results, (r) => {
        return {
          name: r.name,
          group_name: r.group_name,
          score: parseFloat(r.score),
          color: r.color
        }
      })

      return Object.assign({}, lastState, {
        collaborationResults: report,
        collaborationScore: util.round0(action.collaborationScore * 10),
        synergyScore: util.round0(util.mapToRange(action.synergyScore, 0, 0.7, 0, 10) ),
        centralityScore: util.round0( util.mapToRange(action.centralityScore, 0, 1, 0, 10))
      })
    }

    case InteractActions.INTERACT_MAP_NAV_TOGGLE: {
      return Object.assign({}, lastState, {hideNav: !lastState.hideNav})
    }

    default: return lastState
  }
}

/////////////////////////////// Private functions ////////////////////////////////

const hendleErrors = (lastState: IInteractState, action: IInteractAction): IInteractState => {
  let errors = lastState.errors
  errors = errors.concat(action.errors)
  const stmt = Object.assign({}, lastState, {errors: errors })
  return stmt
}

const replaceQuestionnaireInState = (lastState: IInteractState, newQuest: IQuest): IInteractState => {
  const qid = newQuest.id
  const quests = _.cloneDeep( lastState.quests )
  _.remove(quests, q => q.id === qid)
  quests.push(newQuest)
  const newState = Object.assign({}, lastState, {quests: quests})
  return newState
}

const createNewQuestionnaire = (): IQuest => {
  const date = moment().format('YYYY-MM-DD')
  const q: IQuest = {
    id: -1,
    name: `Questionnaire-${date}`,
    stats: [0, 0, 0],
    state: InteractQuestState.created,
    percentCompleted: 0
  }
  return q
}

const addDataFieldsToQuestionnaire = (_quests: IQuest[]): IQuest[] => {
  const quests = _.map(_quests, (q: IQuest) => {
    const quest = _.cloneDeep(q)
    quest.participantsNum = _.sum(quest.stats)
    const comp = quest.stats[3]
    quest.percentCompleted = comp === null || comp === undefined ? 0 : comp / quest.participantsNum
    return quest
  })
  return quests
}
