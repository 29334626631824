import * as _ from 'lodash'

import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service'

import * as util from './util.service'

const trace = util.traceToggle(true)

interface ICache {
  [key: string]: any
}

export type TgetFunction = (params: {[key: string]: any}, successFunc: (data: any) => void) => void

/**
 * Be careful, for now it is only used by interact network APIs
 */
export interface IHttpResult {
  data: any,
  err: string[]
}

@Injectable()
export class DataService {
  cache: ICache

  /* API declarations */
  getSnapshotIds:              TgetFunction
  getTimePickerSnapshotIds:    TgetFunction

  getQuestionnaires:           TgetFunction
  getQuestions:                TgetFunction
  getParticipants:             TgetFunction
  getQuestionnaireData:        TgetFunction
  getMapData:                  TgetFunction

  getEmailsData:               TgetFunction
  getEmailsVolumeScores:       TgetFunction
  getEmailsStats:              TgetFunction
  getEmployeesEmailsScores:    TgetFunction

  getMeetingsData:             TgetFunction
  getMeetingsVolumeScores:     TgetFunction
  getMeetingsStats:            TgetFunction
  getEmployeesMeetingsScores:  TgetFunction

  getDynamicsTimePickerData:   TgetFunction
  getDynamicsStats:            TgetFunction
  getDynamicsScores:           TgetFunction
  getDynamicsEmployeeScores:   TgetFunction
  getDynamicsMap:              TgetFunction
  getDynamicsEmployeeMap:      TgetFunction

  getInterfacesTimePickerData: TgetFunction
  getInterfacesScores:         TgetFunction
  getInterfacesStats:          TgetFunction
  getInterfacesMap:            TgetFunction

  getGroupsData:               TgetFunction
  getConfigParams:             TgetFunction
  getAlertsData:               TgetFunction


  /**
   * Returns a function that handles successful API server results in the sense that
   * there was no HTTP related error like 500 or 404. However, there can still be
   * application specific errors that need to be handled.
   */
  handlePostResult(succssFunc: (a: any) => void, errorFunc: (e: string[]) => void): ((res: any) => void) {
    return (res: any) => {
      const json: IHttpResult = JSON.parse(res['_body'])
      const errors = json.err
      if (errors !== undefined && errors !== null && errors.length > 0) {
        if (errorFunc !== undefined) {
          errorFunc(errors)
        } else {
          alert(errors)
        }
      } else {
        succssFunc(json.data)
      }
    }
  }

  ///////////////////////// Post calls //////////////////////////////
  acknowledgeAlert: (params: {[key: string]: any}) => void = (params: {[key: string]: any}) => {
    this.ajaxService.post(
      'v3/acknowledge_alert',
      params,
      (res: any) => {
          trace('In success for acknowledgeAlert()')
      })
  }

  constructor(private ajaxService: AjaxService) {
    this.cache = {}
    this.getSnapshotIds = this.ajaxGetWithCache('getSnapshotIds', '/v3/get_snapshots')
    this.getTimePickerSnapshotIds = this.ajaxGetWithCache('getTimePickerSnapshotIds', '/v3/get_time_picker_snapshots')

    // Interact
    this.getQuestionnaires = this.ajaxGetWithCache('getQuestionnaires', '/interact_backoffice/get_questionnaires')
    this.getQuestions = this.ajaxGetWithCache('getQuestions', '/interact_backoffice/get_questions')
    this.getParticipants = this.ajaxGetWithCache('getParticipants', '/interact_backoffice/get_participants')
    this.getQuestionnaireData = this.ajaxGetWithCache('getQuestionnaireData', '/v3/get_questionnaire_data')
    this.getMapData = this.ajaxGetWithCache('getMapData', '/v3/get_map_data')

    // Emails
    this.getEmailsData = this.ajaxGetWithCache('getEmailsData', '/v3/get_email_scores')
    this.getEmailsVolumeScores = this.ajaxGetWithCache('getEmailsVolumeScores', '/v3/get_emails_time_picker_data')
    this.getEmailsStats = this.ajaxGetWithCache('getEmailsStats', '/v3/get_email_stats')
    this.getEmployeesEmailsScores = this.ajaxGetWithCache('getEmployeesEmailsScores', '/v3/get_employees_emails_scores')

    // Meetings
    this.getMeetingsData = this.ajaxGetWithCache('getMeetingsData', '/v3/get_meetings_scores')
    this.getMeetingsVolumeScores = this.ajaxGetWithCache('getMeetingsVolumeScores', '/v3/get_meetings_time_picker_data')
    this.getMeetingsStats = this.ajaxGetWithCache('getMeetingsStats', '/v3/get_meetings_stats')
    this.getEmployeesMeetingsScores = this.ajaxGetWithCache('getEmployeesMeetingsScores', '/v3/get_employees_meetings_scores')

    // Dynamics
    this.getDynamicsStats = this.ajaxGetWithCache('getDynamicsStats', '/v3/get_collaboration_stats')
    this.getDynamicsTimePickerData = this.ajaxGetWithCache('getDynamicsTimePickerData', '/v3/get_dynamics_time_picker_data')
    this.getDynamicsScores = this.ajaxGetWithCache('getDynamicsScores', '/v3/get_dynamics_scores')
    this.getDynamicsEmployeeScores = this.ajaxGetWithCache('getDynamicsEmployeeScores', '/v3/get_dynamics_employee_scores')
    this.getDynamicsMap = this.ajaxGetWithCache('getDynamicsMap', '/v3/get_dynamics_map')
    this.getDynamicsEmployeeMap = this.ajaxGetWithCache('getDynamicsEmployeeMap', '/v3/get_dynamics_employee_map')

    // Interfaces
    this.getInterfacesTimePickerData = this.ajaxGetWithCache('getInterfacesTimePickerData', '/v3/get_interfaces_time_picker_data')
    this.getInterfacesScores = this.ajaxGetWithCache('getInterfacesScores', '/v3/get_interfaces_scores')
    this.getInterfacesStats = this.ajaxGetWithCache('getInterfacesStats', '/v3/get_interfaces_stats')
    this.getInterfacesMap = this.ajaxGetWithCache('getInterfacesMap', '/v3/get_interfaces_map')

    // Others
    this.getGroupsData = this.ajaxGetWithCache('getGroupsData', '/v3/get_groups')
    this.getConfigParams = this.ajaxGetWithCache('getConfigParas', '/v3/get_config_params')
    this.getAlertsData = this.ajaxGetWithCache('getAlertsData', '/v3/get_alerts')

  }

///////////////////////////// private methods /////////////////////////

  /*
   * Return a function that will call the given API if result is not already in cache
   * Cache key is created from the name of the calling function and a concatenation
   * of all of the parameters.
   */
  private ajaxGetWithCache(callName: string, url: string) {
    return (params: {[key: string]: any}, successFunc: (data: any) => void) => {
      const cachekey = this.makeCacheKey(callName, params)
      const data = this.cache[cachekey]
      if ( data !== undefined) {
        successFunc( data )
        return
      }

      this.ajaxService.get(url, params,
        (res: any) => {
          // console.log('Received response: ', res, ' for url: ', url)
          let ret
          try {
            ret = JSON.parse( res['_body'])
          } catch (ex) {
            console.log('Error while parsing get result')
            console.log('Result was: ', res['_body'])
            console.error(ex)
            throw ex
          }

          this.cache[cachekey] = _.cloneDeep(ret)
          successFunc(ret)
        })
    }
  }

  private makeCacheKey = (base: string, params: {[key: string]: any}): string => {
    const cachekey =
        _.reduce(params,
                 (newCachKey, propVal, propName) => {
                   return `${newCachKey}-${propName}-${propVal}`
                 },
                 base)
    return cachekey
  }
}


