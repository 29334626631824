import * as Consts from '../constants'
import { IntervalType, SNAPSHOT_INTERVAL_TYPES } from '../constants'
import * as _ from 'lodash'
import { ISnapshot } from '../app.state'

export const pp = (o) => {
  console.log('%%%%%%%%%%%%%%%%%%%%%%')
  Object.keys(o).forEach((v, k) => {
    console.log(o[v])
  })
  console.log('%%%%%%%%%%%%%%%%%%%%%%')
}

/**
 * Pretty print to string
 */
export const ppts = (arr, depth = 1) => {
  let ret = ''
  let ii = 0
  const tab = Array(depth).fill('  ').join('')
  _.forEach(arr, (e: any) => {
    ret = `${ret}\n${tab}[${ii}] -`
    _.forIn(e, (v, k) => {
      if (_.isObject(v)) {
        ret = `${ret} ${k}: ${ppts(v, depth + 1)}`
      } else {
        ret = `${ret} ${k}: ${v}`
      }
    })
    ii += 1
  })
  return ret
}

//////////////////////////////////////// Math ////////////////////////////////////////

/**
 * Maps a value from one range: [fromMin, fromMax] to a new range: [toMin, toMax].
 * If the domain has a long tail at one end and the image is smaller then exceeding
 * values will result in image limits. For example if the domain is a
 * standard deviation, then values higher than 3 are not likely, so we can call
 * the mapping with mapToRange(val, 0, 3, 0, 10) and standard deviations higher than 3
 * will return 10.
 *
 * @param val - Value to be mapped
 * @param fromMin - Domain min
 * @param fromMax - Domain max
 * @param toMin - Image min
 * @param toMax - Image max
 */
export function mapToRange(val: number, fromMin: number, fromMax: number,
                           toMin: number, toMax: number): number {
  if (val >= fromMax) {return toMax}
  if (val <= fromMin) {return toMin}
  const fromDiff = val - fromMin
  const fromLen = fromMax - fromMin
  const toLen = toMax - toMin
  return toMin + ((fromDiff * toLen) / fromLen)
}

export function round2(num: number) {
  return (Math.round(num * 100) / 100)
}

export function round1(num: number) {
  return (Math.round(num * 10) / 10)
}

export function round0(num: number) {
  return Math.round(num)
}

export function isFloat(n): boolean {
    return Number(n) === n && n % 1 !== 0;
}

/**
 * Get min/max values from array
 * @param values - array to find min/max in
 */
export function getMinMaxValues(values: number[]): {min: number, max: number} {
  const res: {min: number, max: number } = {min: -1, max: -1};

  values = _.filter(values, v => v !== undefined);
  res.min = Math.min.apply(null, values);
  res.max = Math.max.apply(null, values);

  return res;
}

////////////////////////////////////////////////////////////////////////////////

export const getPercentChangedColor = (change: number) => {
  if (change <= 0 ) {
    return Consts.DIRECTION_GOOD
  }
  return Consts.DIRECTION_BAD
}

export const arrowDirectionIsDown = (v): boolean => {
  return v <= 0
}

export const capitalize = (s: string): string => {
  if (_.isNil(s)) { return s }
  if (s.length === 0) { return s}
  const firstLetter = s[0].toUpperCase()
  const rest = s.substr(1, s.length - 1)
  return `${firstLetter}${rest}`
}

//////////////////////////////////////// Trace ////////////////////////////////////////
let logCounter = 0;
export const traceToggle = (trace: boolean) => {
  return (...x: any[]): string => {
    if (trace) { console.log(`${logCounter++} [TRACE] ${concatArr(x)}` ) }
    return x[0]
  }
}

export const everLog = (...x: any[]): string => {
  console.log(`[EV] ${concatArr(x)}` )
  return x[0]
}

const concatArr = (x: any[]): string => {
  return _.reduce(
             x,
             (sum: string, curr: string) => {
               const currStr = typeof curr === 'object' ? JSON.stringify(curr) : curr
               sum = `${sum}${currStr}`
               return sum
             },
             '')
}

//////////////////////////////////////// Functional Stuff ////////////////////////////////////////
export const compose = (a: any[]): any => {
  return _.flow(_.reverse(a))
}

export const curry = (a: (...and) => any) => {
  return _.curryRight(a)
}

///////////////////////////////////////// String functions ////////////////////////////////////////
export const arrayToStr = (arr: any[]): string => {
  if (arr === undefined) {return ''}

  let res = ''
  for (let i = 0; i < arr.length; i++) {
    res += String(arr[i]) + (i < arr.length - 1 ? ',' : '');
  }
  return res;
}

export const hashFromString = (str: string): number => {
  if (str === null || str === undefined ) { return null}
  const h = _.reduce(str.split(''), (a, b) => {
    const aa = ( (a << 5) - a ) + b.charCodeAt(0)
    return aa & aa
  }, 0)
  return h
}

//////////////////////////////////////// Time interval utility functions ////////////////////////////////////////

/**
 * Convert string to enum type
 * @param type - type as string
 */
export const getTimeIntervalType = (type: string): number => {
  let typeInt = IntervalType.NotDefined;
  switch (type) {
    case 'By Month':
    typeInt = IntervalType.Month;
    break;
    case 'By Quarter':
    typeInt = IntervalType.Quarter;
    break;
    case 'By 6 Months':
    typeInt = IntervalType.SixMonths;
    break;
    case 'By Year':
    typeInt = IntervalType.Year;
    break;
  }
  return typeInt;
}

export const convertTimeIntervalTypeToString = (type: number): string => {
  switch (type) {
    case IntervalType.Month:
      return SNAPSHOT_INTERVAL_TYPES[0]
    case IntervalType.Quarter:
      return SNAPSHOT_INTERVAL_TYPES[1]
    case IntervalType.SixMonths:
      return SNAPSHOT_INTERVAL_TYPES[2]
    case IntervalType.Year:
      return SNAPSHOT_INTERVAL_TYPES[3]
    default:
      return 'NA'
  }
}

/**
 * Get interval string from snapshot object
 * @param snapshot - snapshot to extract interval from
 * @param intervalType - interval type by which to extract interval
 */
export const getIntervalFromSnapshot = (snapshot: ISnapshot, intervalType: IntervalType): string => {
  let intervalStr = '';
  switch (intervalType) {
    case IntervalType.Month:
    intervalStr = snapshot.month;
    break;
    case IntervalType.Quarter:
    intervalStr = snapshot.quarter;
    break;
    case IntervalType.SixMonths:
    intervalStr = snapshot.half_year;
    break;
    case IntervalType.Year:
    intervalStr = snapshot.year;
    break;
  }
  return intervalStr;
}

/**
 * Check if snapshot has the same interval as the supplied interval
 * @param snapshot - snapshot to check
 * @param interval - interval to check
 * @param intervalType - interval type by which to check
 */
export const isSameInterval = (snapshot: ISnapshot, interval: string, intervalType: IntervalType): boolean => {

  let isSame = false;
  switch (intervalType) {
    case IntervalType.Month:
      isSame = snapshot.month === interval;
      break;
    case IntervalType.Quarter:
      isSame = snapshot.quarter === interval;
      break;
    case IntervalType.SixMonths:
      isSame = snapshot.half_year === interval;
      break;
    case IntervalType.Year:
      isSame = snapshot.year === interval;
      break;
  }
  return isSame;
}

export const getSnapshotsByInterval = (snapshots: ISnapshot[], interval: string, currentIntervalType: IntervalType): number[] => {
  const ids = _.filter(snapshots, (s: ISnapshot) => {
    return isSameInterval(s, interval, currentIntervalType);
  }).map((s: ISnapshot) => s.sid);
  return ids;
}

//////////////////////////////////////// Aggregators over score objects ////////////////////////////////////////
interface IScoreRow {
  score: number
}

/**
 * Will return the sum of the scores in all fields
 */
export const sumIScoreRowArr = (v: IScoreRow[]): number => {
  return _.reduce(v, (sum: number, row: IScoreRow) => sum += row.score, 0)
}

/**
 * Will return the row with the largest score field
 */
export const maxIScoreRowArr = (v: IScoreRow[]): IScoreRow => {
  return _.reduce(v, (maxRow: IScoreRow, row: IScoreRow) => (maxRow.score >= row.score ? maxRow : row), {score: -100000})
}

export const getAggregatorDisplayStr = (name: string): string => {
  let res = '';
  switch (name) {
    case 'Department':
      res = 'DEPRATMENT';
      break;
    case 'Offices':
      res = 'OFFICE';
      break;
    default:
      res = name;
  }
  return res;
}

//////////////////////////////////////// COLOR METHODS ////////////////////////////////////////

export const col = (inx: number, filtered: boolean = false): string => {
  if (_.isNil(inx)) {return 'rgba(0,0,0)'}
  const alpha = filtered ? 1 : 0.2
  return `rgba(${Consts.COLORSRGBA[inx][0]}, ${Consts.COLORSRGBA[inx][1]}, ${Consts.COLORSRGBA[inx][2]}, ${alpha})`
}

export const rgbaToStr = (r: number, g: number, b: number,  alpha: number = 1): string => {
  if (r === undefined || r === null || g === undefined || g === null || b === undefined || b === null) {return 'rgba(0,0,0,0)'}
  if (alpha === undefined || alpha === null) {alpha = 1}
  return 'rgba(' + String(r) + ','  + String(g) + ',' + String(b) + ',' + String(alpha) + ')';
}

/**
 * Method to convert hex color to rgb representation. with optional opacity
 * @param hex - color represeted as hex
 * @param opacity - opacity to return rgb with. Default is 1 - no opacity
 *
 * Credit to: polotek - https://gist.github.com/polotek/1584364
 */
export const hex2rgb = (hex: string, opacity: number = 1): any => {
  hex = (hex + '').trim();

  let rgb = null
  const match = hex.match(/^#?(([0-9a-zA-Z]{3}){1,3})$/);

  if (!match) { return null; }

  rgb = {}

  hex = match[1];
  // check if 6 letters are provided
  if (hex.length === 6) {
    rgb.r = parseInt(hex.substring(0, 2), 16);
    rgb.g = parseInt(hex.substring(2, 4), 16);
    rgb.b = parseInt(hex.substring(4, 6), 16);
  } else if (hex.length === 3) {
    rgb.r = parseInt(hex.substring(0, 1) + hex.substring(0, 1), 16);
    rgb.g = parseInt(hex.substring(1, 2) + hex.substring(1, 2), 16);
    rgb.b = parseInt(hex.substring(2, 3) + hex.substring(2, 3), 16);
  }

  rgb.str = 'rgb' + (opacity ? 'a' : '') + '(';
  rgb.str += rgb.r + ',' + rgb.g + ',' + rgb.b;
  rgb.str += (opacity ? ',' + opacity : '') + ')';

  return rgb;
}

///////////////////////////////////////////////////////////////////////////////////////

/**
 * Convenience method to check if array is defined, not null and has at least 1 element
 */
export const isNotDefinedOrEmpty = (array: any): boolean => {
  return array === undefined || array === null || array.length === 0;
}

//////////////////////////////////////// Algorithms ////////////////////////////////////////

/**
 * Get the display name for algorithm. If algorithm id is missing, return the given name (probably)
 * some raw algorithm name from the server
 * @param aid - algorithm id
 * @param raw_name - default algorithm name
 */
export const getAlgorithmDisplayName = (aid: number, raw_name: string): string => {
  let algorithm_name = raw_name;
  switch (aid) {
    case 100:
      algorithm_name = 'INFORMATION ISOLATES';
      break;
    case 101:
      algorithm_name = 'POWERFUL NON MANAGERS';
      break;
    case 114:
      algorithm_name = 'IN. CHAMPIONS';
      break;
    case 130:
      algorithm_name = 'BOTTLENECKS';
      break;
    case 709:
      algorithm_name = 'RECEIVING';
      break;
    case 710:
      algorithm_name = 'SENDING';
      break;
  }
  return algorithm_name;
}

export const numberToTimeRepresentation = (f: number): string => {
  const decimalPart = f % 1
  const decimalInMinutes = Math.floor(60 * decimalPart)
  const integralPart = Math.floor(f)
  if (decimalInMinutes < 10) {
    return `${integralPart}:0${decimalInMinutes}h`
  }
  return `${integralPart}:${decimalInMinutes}h`
}
