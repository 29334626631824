import { Component, OnInit, OnDestroy } from '@angular/core'
import { select, NgRedux } from '@angular-redux/store'
import { Observable } from 'rxjs/Observable'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import * as _ from 'lodash'

import * as Consts from '../../constants'
import { IntervalType } from '../../constants'

import * as Util from '../../services/util.service'
import * as ms from '../../services/map.service'

import {
  IGroup,
  ISnapshot,
  IAppState,
  IMapData,
  IInterfacesRow,
  IScoreRow,
  ICollaborationSegments,
  IDynamicsProdStats
} from '../../app.state'
import { ITableRow } from '../bars-table/bars-table-util.service'
import { AppActions } from '../../app.actions'
import { InterfacesActions } from './interfaces.actions'
import { Subscription } from 'rxjs/Subscription';

enum ScreenElement {NotDefined, Stats, Scores, Leaderboard}
enum SortBy {Traffic, Sending, Receiving}

const trace = Util.traceToggle(false)

@Component({
  selector: 'sa-app-interfaces',
  templateUrl: './interfaces.component.html',
  styleUrls: ['./interfaces.component.scss']
})
export class InterfacesComponent implements OnInit, OnDestroy {

  /** Main tab color */
  tabColor = '#EA4E61'

  itemsList: string[]
  aggregatorsList: string[]

  views = ['List', 'Map']

  sortBy = SortBy.Sending

  public chart: KeyLines.Chart
  waitForChart = false

  showComingSoonMessage = false

  @select(['interfaces', 'selectedView']) readonly selectedView$: Observable<string>
  selectedViewSub: Subscription

  @select(['interfaces', 'timePickerData']) readonly timePickerData$: Observable<any>
  timePickerDataSub: Subscription

  /** Data to display time picker widget is passed using this subject */
  timePickerDataSubject: BehaviorSubject<any> = new BehaviorSubject(null)

  @select(['global', 'timeIntervalType']) readonly currentTimeIntervalType$: Observable<string>
  currentTimeIntervalTypeSub: Subscription
  timeIntervalTypeStr: string
  timeIntervalType: IntervalType

  @select(['global', 'timeInterval']) readonly timeInterval$: Observable<{currentInterval: string, previousInterval: string}>
  timeIntervalSub: Subscription
  currentInterval: string
  previousInterval: string

  @select(['interfaces', 'aggregator']) readonly aggregator$: Observable<string>
  aggregatorSub: Subscription
  aggregator: string

  @select(['interfaces', 'scores']) readonly interfacesScores$: Observable<IInterfacesRow[]>
  interfacesScoresSub: Subscription
  scores: IInterfacesRow[]
  listScoresSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  @select(['interfaces', 'prodStats']) readonly prodStats$: Observable<IDynamicsProdStats>
  prodStatsSub: Subscription
  collaborationValue: number
  collaborationString: string
  collaborationColor: string
  synergyValue: number
  synergyString: string
  synergyColor: string

  @select(['groups', 'groups']) readonly groups$: Observable<IGroup[]>
  groupsSub: Subscription
  selectedGroups: number[]
  selectedGroupsHash: {[key: string]: number}

  @select(['global', 'showChart']) readonly showChart$: Observable<boolean>
  showChartSub: Subscription
  showChart: boolean;

  @select(['global', 'timePickerSnapshots']) readonly timePickerSnapshots$: Observable<ISnapshot[]>
  timePickerSnapshotsSub: Subscription
  timePickerSnapshots: ISnapshot[]

  @select(['interfaces', 'mapData']) readonly mapData$: Observable<IMapData[]>
  mapDataSub: Subscription

  @select(['interfaces', 'leaderboard']) readonly leaderboard$: Observable<IMapData[]>
  @select(['interfaces', 'empsNum']) readonly empsNum$: Observable<number>
  @select(['interfaces', 'selectedRow']) readonly selectedRow$: Observable<number>
  @select(['interfaces', 'selectedGroupName']) readonly selectedGroupName$: Observable<string>
  @select(['interfaces', 'outTraffic']) readonly outTraffic$: Observable<string>

  @select(['interfaces', 'leaderboardStats']) readonly leaderboardStats$: Observable<IDynamicsProdStats>
  leaderboardStatsSub: Subscription
  lbLeftValue: number
  lbLeftColor: string
  lbLeftTopCaption: string
  lbRightValue: number
  lbRightColor: string
  lbRightTopCaption: string


  constructor(
    public actions: AppActions,
    public interfacesActions: InterfacesActions,
    private ngRedux: NgRedux<IAppState>
  ) {
    this.itemsList = Consts.SNAPSHOT_INTERVAL_TYPES
    this.aggregatorsList = Consts.AGGREGATOR_TYPES_COLLABORATION
    this.onUserToggledView(this.views[0])
  }

  ngOnDestroy() {
    this.leaderboardStatsSub.unsubscribe()
    this.prodStatsSub.unsubscribe()
    this.timeIntervalSub.unsubscribe()
    this.currentTimeIntervalTypeSub.unsubscribe()
    this.showChartSub.unsubscribe()
    this.aggregatorSub.unsubscribe()
    this.groupsSub.unsubscribe()
    this.timePickerSnapshotsSub.unsubscribe()
    this.interfacesScoresSub.unsubscribe()
    this.timePickerDataSub.unsubscribe()
    this.mapDataSub.unsubscribe()
    this.selectedViewSub.unsubscribe()
  }

  ngOnInit() {

    this.selectedViewSub = this.selectedView$.subscribe((state: string) => {
      if (state === 'Map') {
        this.reloadKlChart()
      }
    })

    this.leaderboardStatsSub = this.leaderboardStats$.subscribe((state: IDynamicsProdStats) => {
      if (state === undefined || state === null) { return }
      if (state.closeness === undefined || state.closeness === null) { return }
      this.lbLeftValue = state.closeness.numericVlaue()
      this.lbLeftColor = state.closeness.colorValue()
      this.lbLeftTopCaption = state.closeness.lmhValue()
      this.lbRightValue = state.synergy.numericVlaue()
      this.lbRightColor = state.synergy.colorValue()
      this.lbRightTopCaption = state.synergy.lmhValue()
    })

    this.prodStatsSub = this.prodStats$.subscribe((state: IDynamicsProdStats) => {
      if (state.closeness === undefined || state.closeness === null) { return }
      this.collaborationColor = state.closeness.colorValue()
      this.collaborationString = state.closeness.lmhValue()
      this.collaborationValue = state.closeness.numericVlaue()
      this.synergyString = state.synergy.lmhValue()
      this.synergyValue = state.synergy.numericVlaue()
      this.synergyColor = state.synergy.colorValue()
    })

    this.timeIntervalSub = this.timeInterval$.subscribe(state => {
      this.currentInterval = state.currentInterval
      this.previousInterval = state.previousInterval
      trace('timeInterval$.subscribe - ', state)
      this.fetchAll();
    })

    this.currentTimeIntervalTypeSub = this.currentTimeIntervalType$.subscribe(intervalType => {
      this.timeIntervalType = Util.getTimeIntervalType(this.timeIntervalTypeStr = intervalType)
      this.getTimePickerData(this.timeIntervalType)
    })

    this.showChartSub = this.showChart$.subscribe((state: boolean) => {
      this.showChart = state;
    })

    this.aggregatorSub = this.aggregator$.subscribe(state => {
      this.aggregator = state
      this.fetchAll()
    })

    this.actions.fetchSnapshots({limit: 20})
    this.actions.fetchTimePickerSnapshots({limit: 20})

    this.groupsSub = this.groups$.debounceTime(1000).subscribe((groups: IGroup[]) => {
      const filteredGroups = _.filter(groups, (g: IGroup) => g.isSelected)
      this.selectedGroups = filteredGroups.map((g: IGroup) => g.gid)
      this.selectedGroupsHash = {}
      this.fetchAll()
      this.getTimePickerData(this.timeIntervalType)
    })

    this.timePickerSnapshotsSub = this.timePickerSnapshots$.subscribe(snapshots => {
      trace('timePickerSnapshots$.subscribe - ', snapshots)
      this.timePickerSnapshots = snapshots;
      this.fetchAll()
    })

    this.interfacesScoresSub = this.interfacesScores$.subscribe(scores => {
      trace('InterfacesComponent - In subscribe for: dynamicsScores$')
      this.scores = scores
      const tableScores: IScoreRow[] = this.listToTableScores(scores)
      this.listScoresSubject.next({rawData: tableScores, agg: 'Department'});
    });

    this.timePickerDataSub = this.timePickerData$.subscribe(timePickerData => {
      trace('timePickerData$.subscribe - ', timePickerData)
      if (timePickerData === undefined) {return}
      this.timePickerDataSubject.next({data: timePickerData, displayValues: false, low: 0, high: 2});
    })

    this.mapDataSub = this.mapData$.subscribe( (mapData: IMapData[]) => {
      if (mapData === undefined || mapData.length === 0) { return }

      if (this.chart === undefined) {
        this.waitForChart = true
        return
      }
      this.loadChart(mapData)
    })
  }

  /**
   * Load the keylines chart
   */
  loadChart(mapData: IMapData[]) {
    const options: KeyLines.LayoutOptions = {
      animate: true,
      fixed: this.chart.selection(),
      tidy: true,
      fit: true
    }

    const chartData: KeyLines.ChartData = {
      type: 'LinkChart',
      items: <any>mapData
    }

    this.chart.load( chartData );
    this.chart.layout('standard', options)
  }

  /**
   * The Keylines callback here. Chart plotting happens here
   */
  klChartReady(chart: KeyLines.Chart) {
    trace('InterfacesComp - klChartReady() - keylines chart is ready')
    this.chart = chart;
    if (this.waitForChart) {
      trace('InterfacesComp - klChartReady() - loading chart')
      const mapData = this.ngRedux.getState().interfaces.mapData
      this.loadChart(mapData)
      this.waitForChart = false
    }
  }

  reloadKlChart() {
    (<any>window).klc = this.chart
    const mapData = this.ngRedux.getState().interfaces.mapData
    // this.loadChart(mapData)
    console.log('InterfacesComponent: reloadKlChart() - fitting chart')
    this.chart.zoom('fit')
  }

  /**
   * Will receive events for the chart
   */
  klChartEvents(event: any) {

    /** Hanlde right clicks */
    if (event.name === 'click') { ms.removeContextMenu() }
    if ( event.name === 'contextmenu' ) {
      const mapData: IMapData[] = this.ngRedux.getState().interfaces.mapData
      ms.handleContextMenu(event, mapData)
    }

    if (event.name === 'hover') {
      console.log('Chart hover event triggered: ', event);
    } else if (event.name === 'delete') {
      console.log('Chart delete event triggered: ', event);
      event.preventDefault = true; // Override the default behaviour
    }
  }

  fetchAll = () => {
    this.fetchFromServer(ScreenElement.Stats);
    this.fetchFromServer(ScreenElement.Scores);
  }

  /**
   * Generic method to fetch data from server, for some screen element. Some elements require the same
   * parameters in the server API call, so this method consolidates this functionality.
   *
   * @param elementOnScreen - element on the screen for which to fetch data from server
   */
  fetchFromServer = (elementOnScreen: ScreenElement, gid: number = null) => {
    if (this.timeIntervalType === IntervalType.NotDefined ||
        this.selectedGroups === undefined ||
        this.selectedGroups.length === 0 ||
        this.timePickerSnapshots === undefined) {return}

    if (this.currentInterval === undefined || this.currentInterval.match(/[a-zA-z0-9\/]/) === null) { return }
    if (this.previousInterval === undefined || this.previousInterval.match(/[a-zA-z0-9\/]/) === null) { return }

    const params = {
      interval_type: Util.convertTimeIntervalTypeToString(this.timeIntervalType),
      curr_interval: this.currentInterval,
      prev_interval: this.previousInterval,
      gids: Util.arrayToStr(this.selectedGroups),
      agg_method: this.aggregator
    };

    if (elementOnScreen === ScreenElement.Stats) {
      this.interfacesActions.fetchInterfacesStats(params);
    } else if (elementOnScreen === ScreenElement.Scores) {
      this.interfacesActions.fetchInterfacesScores(params);
    }
  }

  getTimePickerData(currentIntervalType: IntervalType) {
    if (this.timeIntervalType === IntervalType.NotDefined || this.selectedGroups === undefined ||
      this.selectedGroups.length === 0 || this.timePickerSnapshots === undefined) {return}

    const sids = _.map(this.timePickerSnapshots, (s: ISnapshot) => s.sid);
    const sidsStr = Util.arrayToStr(sids);
    const gidsStr = Util.arrayToStr(this.selectedGroups);

    const params = {
      interval_type: Util.convertTimeIntervalTypeToString(this.timeIntervalType)  ,
      sids: sidsStr,
      gids: gidsStr,
      segment_type: this.aggregator
    };
    this.interfacesActions.fetchTimePickerData(params);
  }

  listToTableScores = (scores: IInterfacesRow[]): IScoreRow[] => {
    let ret: IScoreRow[] = []
    let totalVolume = -1
    let percentExt  = -1
    let maxMinVals = {
      'Total Volume': [1000000, -1000000],
      '% External':   [1000000, -1000000],
      '% Sending':    [1000000, -1000000],
      '% Receiving':  [1000000, -1000000]
    }

    _.forEach(scores, (r: IInterfacesRow) => {
      totalVolume = r.intraffic + r.volume
      percentExt = Util.round2(100 * (r.volume / totalVolume))

      // const {ret: ret1, mm: maxMinVals1} = this.updateResult(ret,  maxMinVals,  r, 'Emps Num',     1, r.hierarchy_size )
      const {ret: ret1, mm: maxMinVals1} = this.updateResult(ret, maxMinVals,   r, 'Total Volume', 2, totalVolume, r.hierarchy_size )
      const {ret: ret2, mm: maxMinVals2} = this.updateResult(ret1, maxMinVals1, r, '% External',   3, percentExt,  r.hierarchy_size )
      const {ret: ret3, mm: maxMinVals3} = this.updateResult(ret2, maxMinVals2, r, '% Sending',    4, r.sending,   r.hierarchy_size )
      const {ret: ret4, mm: maxMinVals4} = this.updateResult(ret3, maxMinVals3, r, '% Receiving',  5, r.receiving, r.hierarchy_size )

      ret = ret4
      maxMinVals = maxMinVals4
    })

    _.forEach(ret, (r: IScoreRow) => {
      r.min = maxMinVals[r.algoName][0]
      r.max = maxMinVals[r.algoName][1]
    })

    return ret
  }

  updateResult = (ret:  IScoreRow[], maxMinVals: any, r: IInterfacesRow, algoName: string, aid: number, value: number, hierarchy_size): any => {
    const name = `(${hierarchy_size}) ${r.name}`
    ret.push({
      gid: r.gid,
      groupName: name,
      algoName: algoName,
      curScore: value,
      officeName: '',
      aid: aid,
      prevScore: -1})
    maxMinVals[algoName][0] = maxMinVals[algoName][0] > value ? value : maxMinVals[algoName][0]
    maxMinVals[algoName][1] = maxMinVals[algoName][1] < value ? value : maxMinVals[algoName][1]
    return {ret: ret, mm: maxMinVals}
  }

  ///////////////////////////////// Events /////////////////////////////////
  onUserToggledView = (state: string) => {
    this.interfacesActions.setViewStateFromToggler(state);
  }

  sendingClicked = () => {
    this.scores = _.orderBy(this.scores, ['sending'], ['desc'])
    this.sortBy = SortBy.Sending
  }

  receivingClicked = () => {
    this.scores = _.orderBy(this.scores, ['receiving'], ['desc'])
    this.sortBy = SortBy.Receiving
  }

  departmentClicked = () => {
    this.scores = _.orderBy(this.scores, ['volume'], ['desc'])
    this.sortBy = SortBy.Traffic
  }

  rowSelected = (gid, intraffic, volume, receiving) => {
    this.interfacesActions.setSelectedInterfacesRow(gid, intraffic, volume, receiving)
  }

  onUserSelectedSegment = (rowCol: {row: string, column: string, rowData: ITableRow}) => {
    if (rowCol.rowData === null) { return }

    const rowid: number     = rowCol.rowData.rowid
    let intraffic: number = rowCol.rowData.scores[0].col_score * (1 - (rowCol.rowData.scores[1].col_score / 100))
    intraffic = Math.ceil(intraffic)
    const volume: number    = rowCol.rowData.scores[0].col_score - intraffic
    const receiving: number = rowCol.rowData.scores[3].col_score

    this.interfacesActions.setSelectedInterfacesRow(rowid, intraffic, volume, receiving)
  }
}
