﻿import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-coming-soon-message',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  @Input() showMessage: boolean;

  @Input() topPos: number;
  @Input() leftPos: number;

  @Input() snapToRight = false;

  boxWidth = 120;
  boxHeight = 30;

  arrowSqrSideLength = 15;
  arrowTopPos = 0;
  arrowLeftPos = 85;

  ngOnInit() {
		// Arrow snapped to right is default
    if (!this.snapToRight) {
			// Calc center for arrow horizontally
      this.arrowLeftPos = (this.boxWidth / 2) - (Math.sqrt(2) * this.arrowSqrSideLength / 2);
    }
  }
}
