﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingsComponent } from './settings.component';
import { SettingsPersonalInfoComponent } from './settings-personal-info/settings-personal-info.component';
import { SettingsSecurityComponent } from './settings-security/settings-security.component';
import { SettingsUserManagementComponent } from './settings-user-management/settings-user-management.component';

@NgModule({
  declarations: [
    SettingsComponent,
    SettingsPersonalInfoComponent,
    SettingsSecurityComponent,
    SettingsUserManagementComponent
  ],
imports: [
  BrowserModule,
  FormsModule,
  ReactiveFormsModule
],
exports: [
  SettingsComponent,
  SettingsPersonalInfoComponent,
  SettingsSecurityComponent
]
})

export class SettingsModule {}
