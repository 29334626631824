﻿import { IUser } from '../../app.state'


export enum UserType { NotDefined, Admin, HR, Manager }

export class UserModel implements IUser {
  email: string;
  first_name: string;
  last_name: string;
  user_type: string;
  reports_encryption_key: string;
  session_timeout: number;
  password_update_interval: number;
  max_login_attempts: number;
  required_chars_in_password: any;

  constructor(data: any) {
    this.email = data.email;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.user_type = data.user_type;
    this.reports_encryption_key = data.reports_encryption_key;
    this.session_timeout = data.session_timeout;
    this.password_update_interval = data.password_update_interval;
    this.max_login_attempts = data.max_login_attempts;
    this.required_chars_in_password = data.required_chars_in_password;
  }

  /** Use this to get the user type as enum */
  getUserTypeEnum(): UserType {
    let type: UserType = UserType.NotDefined;
    switch (this.user_type) {
    case 'admin':
      type = UserType.Admin;
    break;
    case 'hr':
      type = UserType.HR;
      break;
    case 'manager':
      type = UserType.Manager;
      break;
    }
    return type;
}

  isEncryptReport(): boolean {
    return (this.reports_encryption_key !== undefined && this.reports_encryption_key !== null && this.reports_encryption_key !== '');
  }

  hasAdminPrivileges() {
    return this.getUserTypeEnum() === UserType.Admin || this.getUserTypeEnum() === UserType.HR;
  }
}
