﻿import { mockGroupsTreeState } from './services/mocks.service'
import { UserModel } from './components/user/user.model'
import { TenBucket } from './types/ten-bucket'

import * as Consts from './constants'

export enum CommunicationState { connected, disconnected }

/** Type of network that is represented by a graph */
export enum NetworkType {na, emails, boolean}

/** ID that can be either string or number */
export type Tid = number | string

export interface IAlert {
  alid: number
  heading: string
  text: string
  state: string
}
export interface IAlertsState {
  numberDisplayed: number,
  alerts: IAlert[]
}
export const INITIAL_STATE_ALERTS = {
  numberDisplayed: 0,
  alerts: []
}

export interface ISnapshot {
  sid: number
  name: string
  month?: string
  quarter?: string
  half_year?: string
  year?: string
}

export interface IEmployeeScoreRow {
  eid: number
  employee_name: string
  gid: number
  group_name: string
  office_name: string
  metric_name: string
  aid: number
  score: number
  scoreBar?: number
  img_url?: number
}

export interface IScoreRow {
  gid: number
  groupName: string
  groupSize?: number
  officeName: string
  algoName: string
  aid: number
  curScore: number
  curNum?: number
  prevScore: number
  prevNum?: number
  min?: number
  max?: number
}

/**
 * Interface for data retrieved from the server for the time picker widget.
 * Represents a single point in the widget. This data may go through some parsing if necessary.
 */
export interface ITimePickerValue {
  score: number,
  time_period: string
}

export interface ISizeWithDiff {
  size: number,
  diff: number
}
export const INITIAL_STATE_ISIZE_WITH_DIFF = {
  size: 0,
  diff: 0
}

export interface ITimeSpentStats {
  totalTimeSpent: ISizeWithDiff,
  averageTimeSpent: ISizeWithDiff,
}
export const INITIAL_TIME_SPENT_STATS: ITimeSpentStats = {
  totalTimeSpent: INITIAL_STATE_ISIZE_WITH_DIFF,
  averageTimeSpent: INITIAL_STATE_ISIZE_WITH_DIFF
}

export interface IEmailsState extends ITimeSpentState {
  isEmail: boolean
}
export const INITIAL_STATE_EMAILS = {
  isEmail: true,
  scores: [],
  employeeScores: [],
  timeSpent: INITIAL_TIME_SPENT_STATS,
  timePickerData: []
}

export interface IMeetingsState extends ITimeSpentState {
  isMeetings: boolean
}
export const INITIAL_STATE_MEETINGS = {
  isMeetings: true,
  scores: [],
  employeeScores: [],
  timeSpent: INITIAL_TIME_SPENT_STATS,
  timePickerData: []
}

export interface ITimeSpentState {
  scores: IScoreRow[],
  employeeScores: IEmployeeScoreRow[]
  timeSpent: ITimeSpentStats,
  timePickerData: ITimePickerValue[]
}
export const INITIAL_STATE_TIME_SPENT = {
  scores: [],
  employeeScores: [],
  timeSpent: INITIAL_TIME_SPENT_STATS,
  timePickerData: []
}

/** Represents selected segment/s in the collaboration tabs - dynamics/interfaces - list view */
export interface ICollaborationSegments {
  row: string,
  column: string
}

// This odd format is specific to Keylines
export interface IMapData {
  type: string
  id: string
  id1?: string
  id2?: string
  c?: string        // color
  color_id?: number
  w?: number        // Link weight
  u?: string        // Image URL
  t?: string        // Node name
  a1?: boolean      // Arrow in source direction
  a2?: boolean      // Arrow in target direction
  g?: string        // 'male' or 'female'
  group_id?: number | string
  orig_group_id?: number | string
  group_name?: string
  group_size?: number
  office_name?: string
  hierarchy_size?: number
  d?: number        // actual weight as returned from the server
  e?: number        // normalized enlargemnt factor as per keylines spedifications
  hi?: boolean      // Used for hiding nodes
  fi?: boolean      // Used for filterig nodes
  role?: string
  rank?: number
  gender?: string
  job_title?: string
  age?: number
  ha0?: any        // A Keylines Halo object for node search
}

export interface IDynamicsProdStats {
  closeness: TenBucket,
  synergy: TenBucket
}

export interface IDynamicsState {
  selectedView: string,
  mapType: string
  selectedSegments: ICollaborationSegments,
  aggregator: string,
  scores: IScoreRow[],
  employeeScores: IEmployeeScoreRow[],
  leaderboardStats: IDynamicsProdStats,
  prodStats: IDynamicsProdStats,
  timePickerData: ITimePickerValue[],
  mapData: IMapData[]
}
export const INITIAL_STATE_DYNAMICS = {
  selectedView: 'List',
  mapType: null,
  selectedSegments: {row: 'NA', column: 'NA'},
  aggregator: Consts.AGGREGATOR_TYPES_COLLABORATION[0],
  scores: [],
  employeeScores: [],
  leaderboardStats: null,
  prodStats: {closeness: null, synergy: null},
  timePickerData: [],
  mapData: []
}

export interface IInterfacesRow {
  name: string,
  gid: number,
  selected?: boolean,
  sending: number,
  receiving: number,
  volume: number,
  intraffic: number,
  hierarchy_size: number
}

export interface IInterfacesState {
  selectedView: string,
  selectedRow: number,
  selectedGroupName: string,
  empsNum: number,
  aggregator: string,
  scores: IInterfacesRow[],
  prodStats: IDynamicsProdStats,
  leaderboard: IInterfacesRow[],
  leaderboardStats: IDynamicsProdStats,
  timePickerData: ITimePickerValue[],
  mapData: IMapData[]
}
export const INITIAL_STATE_INTERFACES: IInterfacesState = {
  selectedView: '',
  selectedRow: -1,
  selectedGroupName: 'All',
  empsNum: 0,
  aggregator: Consts.AGGREGATOR_TYPES_COLLABORATION[0],
  scores: [],
  prodStats: {closeness: null, synergy: null},
  leaderboard: [],
  timePickerData: [],
  leaderboardStats: null,
  mapData: []
}

export interface IUser {
  email: string,
  first_name: string,
  last_name: string,
  user_type: string,
  reports_encryption_key: string,
  session_timeout: number,
  password_update_interval: number,
  max_login_attempts: number,
  required_chars_in_password: string
}

export interface IGroup {
  name: string,
  gid: number,
  parentId: number,
  isSelected: boolean,
  isExpanded: boolean,
  isHidden: boolean,
  childrenIds: number[],
  size: number,
  accumulatedSize?: number,
  depth?: string,
  color?: string
}

export interface IMapGroup {
  name: string,
  gid: Tid,
  parentId: Tid,
  color_id: number
}

export interface IConfigParams {
  incomingEmailToTime: number,
  outgoingEmailToTime: number,
  product_type: string
}

export interface IGroupsHashMap {
  [key: number]: IGroup
}
export interface IGroupsState {
  numberOfSelected: number,
  groups: any
}
export const INITIAL_STATE_GROUPS = {
  numberOfSelected: 0,
  groupInSearch: -1,
  groups: []
}

export interface IGlobalState {
  currentUser: IUser,
  currentTab: string,
  timeIntervalType: string,
  timeInterval: {currentInterval: string, previousInterval: string}
  timePickerSnapshots: ISnapshot[],
  scores: any,
  aggregatorType: string,
  navOpened: boolean,
  alertsOpened: boolean,
  showChart: boolean,
  snapshots: ISnapshot[],
  communiationState: CommunicationState,
  configParams: IConfigParams,
  waitingForReport: boolean,
  spinner: boolean
}

export const INITIAL_STATE_GLOBAL = {
  currentUser: new UserModel({}),
  currentTab: 'emails',
  timeIntervalType: 'By Month',
  timePickerSnapshots: [],
  timeInterval: {currentInterval:  '', previousInterval:  ''},
  scores: {},
  aggregatorType: Consts.AGGREGATOR_TYPES_PRODUCTIVITY[0],
  navOpened: false,
  alertsOpened: false,
  showChart: true,
  snapshots: [],
  communiationState: CommunicationState.connected,
  configParams: {incomingEmailToTime: null, outgoingEmailToTime: null, product_type: ''},
  waitingForReport: false,
  spinner: false
}

///////////////////////// Interact ///////////////////////////////////
export enum InteractTab { collaboration, explore, settings }
export enum InteractQuestTab { quests, quest, questions, participants, test, reports}
export enum InteractQuestState {
  created,
  delivery_method_ready,
  questions_ready,
  notstarted,
  ready,
  sent,
  processing,
  completed
}
export enum InteractDeliveryMethod { email, sms }
export enum InteractHideNames { show, hide }
export enum Gender { unspecified, male, female }
export enum InteractParticipantStatus { notstarted, entered, in_process, completed}
export enum InteractCollaborationTab { none, quests, questions }

export interface IQuest {
  id: number,
  name: string,
  stats?: number[],
  state?: (InteractQuestState | string),
  participantsNum?: number,
  percentCompleted: number,
  sentAt?: string,
  language_id?: number,
  delivery_method?: InteractDeliveryMethod,
  sms_text?: string,
  email_text?: string,
  email_from?: string,
  email_subject?: string,
  test_user_name?: string,
  test_user_phone?: string,
  test_user_email?: string,
  test_user_url?: string,
  hide_names?: InteractHideNames,
  snapshot_id?: number
}

export interface IInteractQuestion {
  id?: number
  title: string,
  body: string,
  order: number,
  dependesOnQuestion?: number,
  min: number,
  max: number,
  active: boolean,
  isNewquestion?: boolean
}

export interface IInteractParticipant {
  pid: number,
  eid: number,
  first_name: string,
  last_name: string,
  rank?: number,
  role?: string,
  job_title?: string,
  office?: string,
  group: string,
  gender?: Gender,
  active: boolean,
  status: InteractParticipantStatus,
  img_url?: string,
  phone_number?: string
}

export interface IInteractTest {
  testParticipantId: number
}

export interface IInteractCollaborationReportRow {
  name: string,
  group_name: string,
  score: number,
  color: string
}

export interface IFilter {
  name: string
  open: boolean
  values: {name: string, active: boolean, color?: number}[]
}
export type TFilterArr = {filterName: string, filterValue: string}[]
export const INITIAL_STATE_MAP_FILTER: IFilter[] = [
  {name: 'Role', open: false, values: []},
  {name: 'Rank', open: false, values: [
    {name: '1', active: false, color: 1},   {name: '2', active: false, color: 2},   {name: '3', active: false, color:  3},
    {name: '4', active: false, color: 4},   {name: '5', active: false, color: 5},   {name: '6', active: false, color:  6},
    {name: '7', active: false, color: 7},   {name: '8', active: false, color: 8},   {name: '9', active: false, color:  9},
    {name: '10', active: false, color: 10}, {name: '11', active: false, color: 11}, {name: '12', active: false, color: 12},
    {name: 'NA', active: false, color: 23}
  ]},
  {name: 'Gender', open: false, values: [{name: 'Male', active: false, color: 3}, {name: 'Female', active: false, color: 14}]},
  {name: 'Office', open: false, values: []},
  {name: 'Job Title', open: false, values: []},
  {name: 'Age', open: false, values: [{name: '21-30', active: false, color: 15},
    {name: '31-40', active: false, color: 16},
    {name: '41-50', active: false, color: 1},
    {name: '51-60', active: false, color: 2},
    {name: '61 and Older', active: false, color: 3},
    {name: 'NA', active: false, color: 23}
  ]},
  {name: 'Structure', open: false, values: []}
]

export interface IMapState {
  selected_eid?: string         // When a map originates from a specific employee, this is his eid
  redraw: boolean              // Wheter the entire map should be redrawn or just update it
  nodes: IMapData[]
  links: IMapData[]
  groups: IMapGroup[]
  origGroups?: IMapGroup[]     // This is where we remember the real departments when colorby changes
  type: NetworkType
  department?: string
  questionnaireName?: string
  questionTitle?: string
  colorBy?: string
  filtersStr?: string
  filters: IFilter[]
  edgesFromFilter: number
  edgesToFilter: number
  showCoreNetwork: boolean
  hideNames: boolean
  uniColors: boolean
  clickToIsolateNodeId?: string
}
export const INITIAL_STATE_MAP: IMapState = {
  redraw: true,
  nodes: [],
  links: [],
  groups: [],
  type: NetworkType.na,
  colorBy: 'Structure',
  filters: INITIAL_STATE_MAP_FILTER,
  edgesFromFilter: 1,
  edgesToFilter: 6,
  showCoreNetwork: false,
  hideNames: false,
  uniColors: false
}

export interface IInteractState {
  openedTab: InteractTab,
  settingsTab: InteractQuestTab,
  collaborationTab: InteractCollaborationTab,
  activeQuestId: number,
  activeQuestionId: number,
  questIsOpened: boolean,
  quests: IQuest[],
  questions: IInteractQuestion[],
  participants: IInteractParticipant[],
  fetchingParticipants: boolean,
  participantsOffset: number,
  collaborationResults: IInteractCollaborationReportRow[],
  collaborationScore?: number,
  centralityScore?: number,
  synergyScore?: number,
  hideNav: boolean,
  participantsSearchText: string,
  errors?: string[]
}
export const INITIAL_STATE_INTERACT: IInteractState = {
  openedTab: InteractTab.explore,
  settingsTab: InteractQuestTab.quests,
  collaborationTab: InteractCollaborationTab.questions,
  activeQuestId: -1,
  activeQuestionId: -1,
  questIsOpened: false,
  quests: [],
  questions: [],
  participants: [],
  fetchingParticipants: true,
  participantsOffset: 0,
  collaborationResults: [],
  collaborationScore: 0,
  hideNav: false,
  participantsSearchText: null,
  errors: []
}

/////////////////////////// Top level state //////////////////////////////////
export interface IAppState {
  global: IGlobalState,
  emails: ITimeSpentState,
  meetings: ITimeSpentState,
  dynamics: IDynamicsState,
  interfaces: IInterfacesState,
  interact: IInteractState,
  groups: IGroupsState,
  alerts: IAlertsState,
  router?: any,
  errors?: string[],
  interactMap: IMapState,
  dynamicsMap: IMapState
}

export const INITIAL_STATE: IAppState = {
  global: INITIAL_STATE_GLOBAL,
  meetings: INITIAL_STATE_TIME_SPENT,
  dynamics: INITIAL_STATE_DYNAMICS,
  interfaces: INITIAL_STATE_INTERFACES,
  interact: INITIAL_STATE_INTERACT,
  emails: INITIAL_STATE_TIME_SPENT,
  groups: INITIAL_STATE_GROUPS,
  alerts: INITIAL_STATE_ALERTS,
  interactMap: INITIAL_STATE_MAP,
  dynamicsMap: INITIAL_STATE_MAP
}
