import { Component, OnInit } from '@angular/core';
import { select, NgRedux } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from 'lodash'

import * as Consts from '../../constants'
import { IntervalType } from '../../constants'

import * as Util from '../../services/util.service'

import {
  IGroup,
  IScoreRow,
  ISnapshot,
  ICollaborationSegments,
  IEmployeeScoreRow,
  IAppState,
  IDynamicsProdStats
} from '../../app.state'
import { AppActions } from '../../app.actions'
import { DynamicsActions } from './dynamics.actions'
import { MapActions } from '../map/map.actions';
import { DataService } from 'app/services/data.service';

enum ScreenElement {NotDefined, Stats, Scores, Leaderboard, LeaderboardStats}
const trace = Util.traceToggle(false)

const ALGORITHM_NAMES = {
  203: 'Bottlenecks',
  204: 'Internal Champions',
  205: 'Information Isolates',
  206: 'Connectors',
  207: 'Deadends',
  208: 'Bypassed Managers'
}
@Component({
  selector: 'sa-app-dynamics',
  templateUrl: './dynamics.component.html',
  styleUrls: ['./dynamics.component.scss']
})
export class DynamicsComponent implements OnInit {

  /** Main tab color */
  tabColor = '#107B76'

  itemsList: string[]
  aggregatorsList: string[]

  views = ['List', 'Map']

  mapNavOpen = true

  showComingSoonMessage = false

  @select(['dynamics', 'selectedView']) readonly selectedView$: Observable<string>

  @select(['dynamics', 'timePickerData']) readonly timePickerData$: Observable<any>

  /** Data to display time picker widget is passed using this subject */
  timePickerDataSubject: BehaviorSubject<any> = new BehaviorSubject(null)

  @select(['global', 'timeIntervalType']) readonly currentTimeIntervalType$: Observable<string>
  timeIntervalTypeStr: string
  timeIntervalType: IntervalType

  @select(['global', 'timeInterval']) readonly timeInterval$: Observable<{currentInterval: string, previousInterval: string}>
  currentInterval: string
  previousInterval: string

  /** Data to display in list view table is passed using this subject */
  listScoresSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  leaderboardDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  selectedSegmentsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  @select(['dynamics', 'aggregator']) readonly aggregator$: Observable<string>
  aggregator: string

  @select(['dynamics', 'scores']) readonly dynamicsScores$: Observable<IScoreRow[]>

  @select(['dynamics', 'prodStats']) readonly prodStats$: Observable<IDynamicsProdStats>
  collaborationValue: number
  collaborationString: string
  collaborationColor: string
  synergyValue: number
  synergyString: string
  synergyColor: string

  @select(['groups', 'groups']) readonly groups$: Observable<IGroup[]>
  selectedGroups: number[]
  selectedGroupsHash: {[key: string]: number}

  @select(['groups', 'numberOfSelected'] ) readonly empsNumber$: Observable<number>
  empsNumber: number

  @select(['global', 'showChart']) readonly showChart$: Observable<boolean>
  showChart: boolean

  @select(['global', 'timePickerSnapshots']) readonly timePickerSnapshots$: Observable<ISnapshot[]>
  timePickerSnapshots: ISnapshot[]

  @select(['dynamics', 'selectedSegments']) readonly segments$: Observable<ICollaborationSegments>
  selectedGroup: string
  selectedAlgorithm: string
  selectedAlgorithmName = ALGORITHM_NAMES[206]

  /** Scores for leaderboard */
  @select(['dynamics', 'employeeScores']) readonly employeeScores$: Observable<IEmployeeScoreRow[]>

  @select(['dynamics', 'leaderboardStats']) readonly leaderboardStats$: Observable<IDynamicsProdStats>
  lbRightValue: number
  lbRightColor: string
  lbRightTopCaption: string

  constructor(
    public actions: AppActions,
    public dynamicsActions: DynamicsActions,
    private mapActions: MapActions
  ) {
    this.itemsList = Consts.SNAPSHOT_INTERVAL_TYPES
    this.aggregatorsList = Consts.AGGREGATOR_TYPES_COLLABORATION
    this.onUserToggledView(this.views[0])
  }

  ngOnInit() {

    this.empsNumber$.subscribe((state: number) => {
      this.empsNumber = state
    })

    this.leaderboardStats$.subscribe((state: IDynamicsProdStats) => {
      if (state === undefined || state === null) { return }
      if (state.synergy === undefined || state.synergy === null) { return }
      this.lbRightValue = state.synergy.numericVlaue()
      this.lbRightColor = state.synergy.colorValue()
      this.lbRightTopCaption = state.synergy.lmhValue()
    })

    this.prodStats$.subscribe((state: IDynamicsProdStats) => {
      if (state.closeness === undefined || state.closeness === null) { return }
      this.collaborationColor = state.closeness.colorValue()
      this.collaborationString = state.closeness.lmhValue()
      this.collaborationValue = state.closeness.numericVlaue()
      this.synergyString = state.synergy.lmhValue()
      this.synergyValue = state.synergy.numericVlaue()
      this.synergyColor = state.synergy.colorValue()
    })

    this.timeInterval$.subscribe(state => {
      this.currentInterval = state.currentInterval
      this.previousInterval = state.previousInterval
      this.fetchAll();
    })

    this.currentTimeIntervalType$.subscribe(intervalType => {
      this.timeIntervalType = Util.getTimeIntervalType(this.timeIntervalTypeStr = intervalType);
      this.getTimePickerData(this.timeIntervalType)
    })

    this.showChart$.subscribe((state: boolean) => {
      this.showChart = state
    })

    this.aggregator$.subscribe(state => {
      this.aggregator = state
      this.fetchAll()
    })

    this.actions.fetchSnapshots({limit: 20});
    this.actions.fetchTimePickerSnapshots({limit: 20});

    this.groups$.debounceTime(1000).subscribe((groups: IGroup[]) => {
      const filteredGroups = _.filter(groups, (g: IGroup) => g.isSelected)
      this.selectedGroups = filteredGroups.map((g: IGroup) => g.gid)
      this.selectedGroupsHash = {}

      // build has for later uses. It is needed because the bars-table maintains groups
      //   in terms of names instead of IDs
      _.forEach(filteredGroups, (g: IGroup) => {
        const name = g.name.indexOf('_') === -1 ? g.name : g.name.split('_')[1]
        this.selectedGroupsHash[name] = g.gid
      })


      this.fetchAll();
      this.getTimePickerData(this.timeIntervalType);
    })

    this.timePickerSnapshots$.subscribe(snapshots => {
      this.timePickerSnapshots = snapshots;
      this.fetchAll();
    })

    this.dynamicsScores$.subscribe(scores => {
      trace('DynamicsComponent - In subscribe for: socres')
      this.listScoresSubject.next({rawData: scores, agg: this.aggregator});
    });

    this.timePickerData$.subscribe(timePickerData => {
      if (timePickerData === undefined) {return}
      this.timePickerDataSubject.next({data: timePickerData, displayValues: false, low: 0, high: 2});
    })

    this.employeeScores$.subscribe(scores => {
      this.leaderboardDataSubject.next(scores);
    })

    this.segments$.subscribe( (state: ICollaborationSegments) => {
      if (state === undefined) { return }

      // If the group name has been augmented with a group id then need to remove it
      if (state.row.indexOf('_') !== -1) {
        this.selectedGroup = state.row.split('_')[1]
      } else {
        this.selectedGroup = state.row
      }

      this.selectedAlgorithm = state.column
      if (this.selectedAlgorithm === 'NA' || this.selectedAlgorithm === '') {
        this.selectedAlgorithmName = ALGORITHM_NAMES[206]
      } else {
        this.selectedAlgorithmName = ALGORITHM_NAMES[this.selectedAlgorithm]
      }

      if (this.selectedGroupsHash === undefined) { return }

      const gid = this.selectedGroupsHash[this.selectedGroup]
      if (gid === undefined) {
        this.fetchFromServer(ScreenElement.Leaderboard)
      } else {
        this.fetchFromServer(ScreenElement.Leaderboard, gid)
        this.fetchFromServer(ScreenElement.LeaderboardStats, gid)
      }
    })
  }

  employeeClickedOnLeaderboard = (e: any) => {
    this.dynamicsActions.toggleViewState('Map')
    this.dynamicsActions.fetchEmployeeDynamicsMap(e['eid'], e['aid'], this.mapActions)
  }

  fetchAll = () => {
    this.fetchFromServer(ScreenElement.Stats);
    this.fetchFromServer(ScreenElement.Scores);
    this.fetchFromServer(ScreenElement.Leaderboard);
  }

  /**
   * Generic method to fetch data from server, for some screen element. Some elements require the same
   * parameters in the server API call, so this method consolidates this functionality.
   *
   * @param elementOnScreen - element on the screen for which to fetch data from server
   */
  fetchFromServer = (elementOnScreen: ScreenElement, gid: number = null) => {
    if (this.timeIntervalType === IntervalType.NotDefined ||
        this.selectedGroups === undefined ||
        this.selectedGroups.length === 0 ||
        this.timePickerSnapshots === undefined) {return}

    if (this.currentInterval === undefined || this.currentInterval.match(/[a-zA-z0-9\/]/) === null) { return }
    if (this.previousInterval === undefined || this.previousInterval.match(/[a-zA-z0-9\/]/) === null) { return }

    const params = {
      interval_type: Util.convertTimeIntervalTypeToString(this.timeIntervalType),
      curr_interval: this.currentInterval,
      prev_interval: this.previousInterval,
      gids: Util.arrayToStr(this.selectedGroups),
      agg_method: this.aggregator,
      aid: undefined
    }
    if (this.selectedAlgorithm !== undefined && this.selectedAlgorithm !== 'NA') {
      params.aid = this.selectedAlgorithm
    }

    if (elementOnScreen === ScreenElement.Stats) {
      this.dynamicsActions.fetchDynamicsStats(params);
    } else if (elementOnScreen === ScreenElement.Scores) {
      this.dynamicsActions.fetchDynamicsScores(params);
    } else if (elementOnScreen === ScreenElement.Leaderboard) {
      if (gid !== null) {
        params.gids = Util.arrayToStr([gid])
      }

      trace('DynamicsComponent - fetchFromServer() - fetching employees with params: ', params)
      this.dynamicsActions.fetchEmployees(params);
      this.dynamicsActions.fetchEmployeesStats(params)
    }
  }

  getTimePickerData(currentIntervalType: IntervalType) {
    if (this.timeIntervalType === IntervalType.NotDefined || this.selectedGroups === undefined ||
      this.selectedGroups.length === 0 || this.timePickerSnapshots === undefined) {return}

    const sids = _.map(this.timePickerSnapshots, (s: ISnapshot) => s.sid);
    const sidsStr = Util.arrayToStr(sids);
    const gidsStr = Util.arrayToStr(this.selectedGroups);

    const params = {
      interval_type: Util.convertTimeIntervalTypeToString(this.timeIntervalType)  ,
      sids: sidsStr,
      gids: gidsStr,
      segment_type: this.aggregator
    };
    this.dynamicsActions.fetchTimePickerData(params);
  }

  onUserToggledView = (state: string) => {
    let res = null
    if (state === 'Map') {
      res = this.dynamicsActions.fetchDynamicsGroupMap( this.mapActions )
    }
    this.dynamicsActions.setViewStateFromToggler(state, res)
  }

  onUserSelectedSegment = (rowCol: {row: string, column: string}) => {
    const selected: ICollaborationSegments = {
      row: rowCol.row,
      column: rowCol.column
    }

    this.dynamicsActions.setSelectedDynamicsSegments(selected);
    this.selectedSegmentsSubject.next({segments: selected, aggregator: this.aggregator});
  }
}
