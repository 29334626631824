﻿import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Action } from 'redux';

import { IAppState,
         ISnapshot,
         IGlobalState,
         CommunicationState,
         IConfigParams,
         IGroupsHashMap,
         IGroup} from './app.state'
import { UserModel, UserType } from './components/user/user.model'
import { DataService } from './services/data.service'
import { AjaxService } from './services/ajax.service'
import { getActiveGroups } from './services/groups.service'

import * as util from './services/util.service'

const trace = util.traceToggle(false)


export interface IAppAction extends Action {
  userData: UserModel
  tabName?: string
  intervalType?: string
  timePickerSnapshots?: ISnapshot[]
  currentInterval?: number
  previousInterval?: number
  scores?: any
  aggregatorType?: string
  snapshots?: ISnapshot[]
  configParams?: IConfigParams
}

@Injectable()
export class AppActions {

  static USER_SIGNED_IN  = 'app user signed in'
  static USER_IS_SIGNED_IN  = 'app user signed in'
  static USER_SIGNED_OUT = 'app user signed out'
  static TAB_CHANGED = 'app tab changed'
  static TIME_INTERVAL_TYPE_CHANGED = 'app time interval type changed'

  static USER_SELECTED_TIME_INTERVAL = 'app user selected time interval'
  static USER_SELECTED_TIME_INTERVAL_SCORES = 'app user selected time interval scores'

  static AGGREGATOR_TYPE_CHANGED = 'app aggregator type changed'

  static NAV_BAR_TOGGLED = 'app Nav bar toggled'
  static NAV_BAR_HIDE = 'app Nav bar hide'

  static ALERTS_TOGGLED = 'app alerts toggled'
  static ALERTS_HIDE    = 'app alerts hide'

  static FETCH_SNAPSHOTS         = 'app fetch snapshots'
  static FETCH_SNAPSHOTS_SUCCESS = 'app fetch snapshots success'
  static FETCH_SNAPSHOTS_FAIL    = 'app fetch snapshots fail'

  static FETCH_TIME_PICKER_SNAPSHOTS         = 'app fetch time picker snapshots'
  static FETCH_TIME_PICKER_SNAPSHOTS_SUCCESS = 'app fetch time picker snapshots success'
  static FETCH_TIME_PICKER_SNAPSHOTS_FAIL    = 'app fetch time picker snapshots fail'

  static FETCH_CONFIG_PARAMS         = 'app fetch config paras'
  static FETCH_CONFIG_PARAMS_SUCCESS = 'app fetch config paras success'
  static FETCH_CONFIG_PARAMS_FAIL    = 'app fetch config paras fail'

  static SNAPSHOTS_CHART_TOGGLE = 'app snapshots chart toggle'

  static SERVER_DISCONNECTED = 'app server disconnected'
  static SERVER_CONNECTED    = 'app server connected'

  static DOWNLOAD_REPORT = 'app download reort'
  static DOWNLOAD_REPORT_SUCCESS = 'app download reort success'
  static DOWNLOAD_REPORT_FAIL = 'app download reort fail'

  static SPINNER_ON  = 'app spinner on'
  static SPINNER_OFF = 'app spinner off'

  static create_report_url = (currentTab: string) => {
    if (currentTab === 'emails')   { return '/v3/get_emails_excel_report'}
    if (currentTab === 'dynamics') { return '/v3/get_dynamics_excel_report'}
    throw Error(`Don't know how to create url for tabl: ${currentTab}`)
  }

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private dm: DataService,
    private as: AjaxService
  ) {}

  serverConnected = (): void => {
    if (this.ngRedux.getState().global.communiationState === CommunicationState.disconnected) {
      alert('Server is reachable now')
    }
    this.ngRedux.dispatch({type: AppActions.SERVER_CONNECTED})
  }

  serverDisConnected = (): void => {
    if (this.ngRedux.getState().global.communiationState === CommunicationState.connected) {
      alert('Server is not reachable')
    }
    this.ngRedux.dispatch({type: AppActions.SERVER_DISCONNECTED})
  }

  navBarToggled = (): void => {
    this.ngRedux.dispatch({type: AppActions.NAV_BAR_TOGGLED})
  }

  alertsToggled = (): void => {
    this.ngRedux.dispatch({type: AppActions.ALERTS_TOGGLED})
  }

  spinnerOn = (): void => {
    this.ngRedux.dispatch({type: AppActions.SPINNER_ON})
  }

  spinnerOff = (): void => {
    this.ngRedux.dispatch({type: AppActions.SPINNER_OFF})
  }

  snapshotsChartToggled = (): void => {
    this.ngRedux.dispatch({type: AppActions.SNAPSHOTS_CHART_TOGGLE})
  }

  hideNavBar = (): void => {
    this.ngRedux.dispatch({ type: AppActions.NAV_BAR_HIDE })
  }

  timeIntervalTypeChanged = (intervalType: string): void => {
    this.ngRedux.dispatch({
      type: AppActions.TIME_INTERVAL_TYPE_CHANGED,
      intervalType: intervalType
    })
  }

  userSelectedInterval = (currentInterval: number, previousInterval: number, scores: any): void => {
    this.ngRedux.dispatch({type: AppActions.USER_SELECTED_TIME_INTERVAL_SCORES, scores: scores})
    this.ngRedux.dispatch({
      type: AppActions.USER_SELECTED_TIME_INTERVAL,
      previousInterval: previousInterval,
      currentInterval: currentInterval})
  }

  aggregatorTypeChanged = (aggregatorType: string): void => {
    this.ngRedux.dispatch({
      type: AppActions.AGGREGATOR_TYPE_CHANGED,
      aggregatorType: aggregatorType
    })
  }

  userSignedInAction = (userData: UserModel): void => {
    this.ngRedux.dispatch({
      type: AppActions.USER_SIGNED_IN,
      userData: userData
    })
  }

  userSignedIsInAction = (userData: UserModel): void => {
    this.ngRedux.dispatch({
      type: AppActions.USER_IS_SIGNED_IN,
      userData: userData
    })
  }

  userSignedOutAction = (): void => {
    trace('AppActions - in userSignedOutAction()')
    this.ngRedux.dispatch({type: AppActions.USER_SIGNED_OUT})
  }

  tabChanged = (tabName: string): void => {
    this.ngRedux.dispatch({
      type: AppActions.TAB_CHANGED,
      tabName: tabName
    })
  }

  /* Fetch snapshots */
  fetchSnapshots(params): void {
    this.ngRedux.dispatch({type: AppActions.FETCH_SNAPSHOTS})
    this.dm.getSnapshotIds(params, (snapshots) => {
      this.fetchSnapshotsSuccess(snapshots)
    })
  }

  fetchSnapshotsSuccess(snapshots): void {
    this.ngRedux.dispatch({
      type: AppActions.FETCH_SNAPSHOTS_SUCCESS,
      snapshots: snapshots
    })
  }

  fetchSnapshotsFail(): void {
    this.ngRedux.dispatch({type: AppActions.FETCH_SNAPSHOTS_FAIL})
  }

  /* Fetch snapshots */
  fetchTimePickerSnapshots(params): void {
    this.ngRedux.dispatch({type: AppActions.FETCH_TIME_PICKER_SNAPSHOTS})
    this.dm.getTimePickerSnapshotIds(params, (snapshots) => {
      this.fetchTimePickerSnapshotsSuccess(snapshots)
    })
  }

  private fetchTimePickerSnapshotsSuccess(snapshots): void {
    this.ngRedux.dispatch({
      type: AppActions.FETCH_TIME_PICKER_SNAPSHOTS_SUCCESS,
      timePickerSnapshots: snapshots
    })
  }

  private fetchTimePickerSnapshotsFail(): void {
    this.ngRedux.dispatch({type: AppActions.FETCH_TIME_PICKER_SNAPSHOTS_FAIL})
  }

  /* Config paramerters */
  fetchConfigParams(): void {
    this.ngRedux.dispatch({type: AppActions.FETCH_CONFIG_PARAMS})
    this.dm.getConfigParams({}, (confParams) => {
      trace('AppActions - in fetchConfigParams(). confParams: ', confParams)
      this.fetchConfigParamsSuccess(confParams)
    })
  }

  private fetchConfigParamsSuccess(confParams): void {
    const configParams: IConfigParams = {
      incomingEmailToTime: confParams.incomingEmailToTime,
      outgoingEmailToTime: confParams.outgoingEmailToTime,
      product_type: confParams.product_type
    }
    this.ngRedux.dispatch({
      type: AppActions.FETCH_CONFIG_PARAMS_SUCCESS,
      configParams: configParams
    })
  }

  private fetchConfigParamsFail(): void {
    this.ngRedux.dispatch({type: AppActions.FETCH_CONFIG_PARAMS_FAIL})
  }

  downLoadReport = () => {
    trace('AppActions - in downLoadReport() - start');
    const state = this.ngRedux.getState()

    if (state.global.waitingForReport) { return }

    this.ngRedux.dispatch({
      type: AppActions.DOWNLOAD_REPORT
    })

    const groups: IGroup[] = state.groups.groups
    const selectedGroups: number[] = getActiveGroups(groups)
    const currentTab: string =  state.global.currentTab
    const interval: string = state.global.timeInterval.currentInterval
    const intervalType: string = state.global.timeIntervalType

    this.as.download(
      AppActions.create_report_url(currentTab),
      {
        gids: selectedGroups,
        interval: interval,
        interval_type: intervalType
      },
      () => {
        trace('AppActions - in downLoadReport() - success')
        this.downLoadReportSuccess()
      }
    )
  }

  downLoadReportSuccess = () => {
    this.ngRedux.dispatch({
      type: AppActions.DOWNLOAD_REPORT_SUCCESS
    })
  }
}
