import { Injectable } from '@angular/core'
import { NgRedux } from '@angular-redux/store'
import { Action } from 'redux'
import * as _ from 'lodash'
import {traceToggle} from '../../services/util.service'

import {
  IDynamicsState,
  IScoreRow,
  ICollaborationSegments,
  IEmployeeScoreRow,
  IMapData,
  IGlobalState,
  IDynamicsProdStats,
  IMapState
} from '../../app.state'
import { DataService } from '../../services/data.service'
import { CombineService } from '../map/combine.service'
import { MapActions } from '../map/map.actions'
import { EXPLORE_DYNAMICS } from '../../constants'
import * as graphService from '../../services/graph.service'

export interface IDynamicsAction extends Action {
  selectedView?: string,
  selectedSegments?: ICollaborationSegments,
  aggregator?: string,
  dynamicsScores?: IScoreRow[],
  prodStats?: any
  employeeScores?: IEmployeeScoreRow[],
  employeeStats?: any,
  timePickerData?: any,
  mapData?: {nodes: any[], links: any[], groups: any[], result_type: string}
}

const trace = traceToggle(false)

@Injectable()
export class DynamicsActions {

  static FETCH_DYNAMICS_STATS                    = 'dynamics fetch dynamics stats'
  static FETCH_DYNAMICS_STATS_SUCCESS            = 'dynamics fetch dynamics stats success'

  static FETCH_DYNAMICS_TIME_PICKER_DATA         = 'dynamics fetch dynamics time picker'
  static FETCH_DYNAMICS_TIME_PICKER_DATA_SUCCESS = 'dynamics fetch dynamics time picker success'

  static AGGREGATOR_TYPE_CHANGED                 = 'dynamics aggregator type changed dynamics'

  static USER_TOGGLED_VIEW_STATE                 = 'dynamics user toggled view'

  static FETCH_DYNAMICS_GROUP_MAP                = 'dynamics fetch group map'
  static FETCH_DYNAMICS_GROUP_MAP_SUCCESS        = 'dynamics fetch group map success'
  static FETCH_DYNAMICS_GROUP_MAP_FAIL           = 'dynamics fetch group map fail'

  static FETCH_DYNAMICS_EMPLOYEE_MAP             = 'dynamics fetch employee map'
  static FETCH_DYNAMICS_EMPLOYEE_MAP_SUCCESS     = 'dynamics fetch employee map success'
  static FETCH_DYNAMICS_EMPLOYEE_MAP_FAIL        = 'dynamics fetch employee map fail'

  static FETCH_DYNAMICS_SCORES                   = 'dynamics fetch dynamics scores'
  static FETCH_DYNAMICS_SCORES_SUCCESS           = 'dynamics fetch dynamics scores success'

  static USER_SELECTED_SEGMENTS                  = 'dynamics user selected segments'

  static FETCH_EMPLOYEES                         = 'dynamics fetch employees'
  static FETCH_EMPLOYEES_SUCCESS                 = 'dynamics fetch employees success'
  static FETCH_EMPLOYEES_FAIL                    = 'dynamics fetch employees fail'

  static FETCH_EMPLOYEES_STATS                   = 'dynamics fetch employees stats'
  static FETCH_EMPLOYEES_STATS_SUCCESS           = 'dynamics fetch employees stats success'
  static FETCH_EMPLOYEES_STATS_FAIL              = 'dynamics fetch employees stats fail'

  static FETCH_SCORES_STOPPED                    = 'dynamics fetch scores stopped'

  cs: CombineService

  constructor(
    private ngRedux: NgRedux<IDynamicsState>,
    private ngReduxGlobal: NgRedux<IGlobalState>,
    private ngReduxMap: NgRedux<IMapState>,
    private ds: DataService
  ) {
    this.cs = new CombineService()
  }

  fetchDynamicsStats = (params) => {
    this.ngRedux.dispatch({type: DynamicsActions.FETCH_DYNAMICS_STATS})
    this.ds.getDynamicsStats(params, (dynamicsStats) =>
      this.fetchDynamicsStatsSuccess(dynamicsStats));
  }

  private fetchDynamicsStatsSuccess = (dynamicsStats) => {
    this.ngRedux.dispatch({
      type: DynamicsActions.FETCH_DYNAMICS_STATS_SUCCESS,
      prodStats: dynamicsStats
    })
  }

  fetchTimePickerData = (params) => {
    this.ngRedux.dispatch({type: DynamicsActions.FETCH_DYNAMICS_TIME_PICKER_DATA})
    this.ds.getDynamicsTimePickerData(params, (dynamicsTimePickerData) =>
      this.fetchTimePickerDataSuccess(dynamicsTimePickerData));
  }

  private fetchTimePickerDataSuccess = (dynamicsTimePickerData) => {
    this.ngRedux.dispatch({
      type: DynamicsActions.FETCH_DYNAMICS_TIME_PICKER_DATA_SUCCESS,
      timePickerData: dynamicsTimePickerData
    })
  }

  aggregatorTypeChanged = (aggregator: string): void => {
    this.ngRedux.dispatch({
      type: DynamicsActions.AGGREGATOR_TYPE_CHANGED,
      aggregator: aggregator
    })
  }

  setViewStateFromToggler = (selectedView: string, res) => {
    this.ngRedux.dispatch({
      type: DynamicsActions.USER_TOGGLED_VIEW_STATE,
      selectedView: selectedView
    })

    if (selectedView === 'Map') {

      // If didn't fetch any map, probably because no group was selelcted
      if (res !== 'ok') {
        this.ngRedux.dispatch({
          type: DynamicsActions.USER_TOGGLED_VIEW_STATE,
          selectedView: 'List'
        })
      }

    }
  }

  /**
   * Same as setViewState above only it doens't fetch new
   * map data.
   */
  toggleViewState = (selectedView: string) => {
    trace('In toggleViewState(), selectedView: ', selectedView)
    this.ngRedux.dispatch({
      type: DynamicsActions.USER_TOGGLED_VIEW_STATE,
      selectedView: selectedView
    })
  }

  /////////////////////////////// Maps //////////////////////////////////////////////////

  /**
   * Fetch the dynamics map for a group and send the results
   * to the mapAction
   */
  fetchDynamicsGroupMap = (mapActions: MapActions) => {
    const state = this.ngRedux.getState()
    const interval: string = (<any>state).global.timeInterval.currentInterval

    let groupName: string = (<any>state).dynamics.selectedSegments.row
    if (groupName === '') {
      console.warn('Nothing selected')
      alert('Cannot display map without a group')
      return 'no group selected'
    }
    if (groupName.indexOf('_') !== -1) {
      const s = groupName.split('_')
      groupName = s[1]
    }

    let aid: string = (<any>state).dynamics.selectedSegments.column
    if (aid === '' || aid === 'NA') {
      aid = '707'
    }

    this.ds.getDynamicsMap({
      group_name: groupName,
      interval: interval,
      aid: aid
    }, (mapData) => {
      // Delegating handling the result to the dynamics MapActions which
      // maintains an updated CombineService
      mapActions.fetchMapDataSuccess(EXPLORE_DYNAMICS,  mapData)
    })

    this.ngRedux.dispatch({ type: DynamicsActions.FETCH_DYNAMICS_GROUP_MAP })
    return 'ok'
  }

  fetchEmployeeDynamicsMap = (eid: number, aid: any, mapActions: MapActions) => {
    const state = this.ngRedux.getState()
    const interval: string = (<any>state).global.timeInterval.currentInterval

    this.ds.getDynamicsEmployeeMap({
      eid: eid,
      interval: interval,
      aid: aid
    }, (mapData) => {
      // Delegating handling the result to the dynamics MapActions which
      // maintains an updated CombineService
      mapActions.fetchMapDataSuccess(EXPLORE_DYNAMICS,  mapData)
    })

    this.ngRedux.dispatch({ type: DynamicsActions.FETCH_DYNAMICS_EMPLOYEE_MAP })
  }

/////////////////////////////////////////////////////////////////////////////////////

  setSelectedDynamicsSegments = (selectedSegments: ICollaborationSegments) => {
    this.ngRedux.dispatch({
      type: DynamicsActions.USER_SELECTED_SEGMENTS,
      selectedSegments: selectedSegments
    })
  }

  fetchDynamicsScores = (params) => {
    this.ngRedux.dispatch({type: DynamicsActions.FETCH_DYNAMICS_SCORES})
    this.ds.getDynamicsScores(params, (dynamicsScores) =>
      this.fetchDynamicsScoresSuccess(dynamicsScores));
  }

  private fetchDynamicsScoresSuccess = (dynamicsScores) => {
    this.ngRedux.dispatch({
      type: DynamicsActions.FETCH_DYNAMICS_SCORES_SUCCESS,
      dynamicsScores: dynamicsScores
    })
  }

  /** Employees (Leaderboard) */
  fetchEmployees = (params): void => {
    this.ngRedux.dispatch({type: DynamicsActions.FETCH_EMPLOYEES})
    this.ds.getDynamicsEmployeeScores(params, (employeeScores) => {
      this.fetchEmployeesSuccess(employeeScores);
    })
  }

  fetchEmployeesSuccess(employeeScores): void {
    this.ngRedux.dispatch({
      type: DynamicsActions.FETCH_EMPLOYEES_SUCCESS,
      employeeScores: employeeScores
    })
  }

  fetchEmployeesFail(): void {
    this.ngRedux.dispatch({type: DynamicsActions.FETCH_EMPLOYEES_FAIL});
  }

  /** Employee Stats */
  fetchEmployeesStats = (params): void => {
    this.ngRedux.dispatch({type: DynamicsActions.FETCH_EMPLOYEES_STATS})
    this.ds.getDynamicsStats(params, (stats) => {
      this.fetchEmployeesStatsSuccess(stats);
    })
  }
  fetchEmployeesStatsSuccess(stats): void {
    this.ngRedux.dispatch({
      type: DynamicsActions.FETCH_EMPLOYEES_STATS_SUCCESS,
      employeeStats: stats
    })
  }

  fetchEmployeesStatsFail(): void {
    this.ngRedux.dispatch({type: DynamicsActions.FETCH_EMPLOYEES_STATS_FAIL});
  }
}
